import React, { useState } from "react";
import { message, Modal, Button, Card, Alert, Form, Input } from "antd";
import { StructuresComponent } from "../Admin/UsersScreen";
import { PersonSearch } from "../Search/SearchScreen";
import ResultsContainer from "../Search/ResultsContainer";
import { Box, Flex } from "rebass";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { validatePhoneNumber } from "../../common/utils";

const STRUCTURE_MAP = {
  region: "Region",
  national: "National",
  constituency: "Constituency",
  pd: "PollingDivision",
  ps: "PollingStation",
  electoralDivision: "ElectoralDivision",
  cluster: "Cluster",
};

const AddGotvRole = ({ structure: initialStructure, tags, structures }) => {
  const [visible, toggleVisible] = useState(false);
  const [showSearch, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [structure, setStructure] = useState(initialStructure);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(null);
  const [voter, setVoter] = useState(null);
  const [addGotvRole] = useMutation(ADD_GOTV_ROLE);

  const onSetStructure = (payload) => {
    setStructure({
      ...structure,
      ...payload,
    });
  };

  const onChange = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onReset = () => {
    setSearch(false);
    setLoading(false);
    setVoter(null);
    setValues({});
  };

  const onAddUser = () => {
    setErrors("");

    let hasParams = true;

    if (!structure.role) {
      hasParams = false;
    } else {
      const { requires } = tags.find((r) => r.id === structure.role);

      requires.forEach((r) => {
        const options = r.split("|");

        console.log(options);

        const check = options.some((option) => {
          console.log(option, structure[option]);
          return structure[option];
        });

        console.log(r, check);

        if (!check) {
          hasParams = false;
        }
      });
    }

    if (!hasParams) {
      setErrors("Please select all required structure fields for the role.");
      return;
    }

    let formErrors = "";

    if (!voter) {
      formErrors = "Invalid voter profile. Please try again.";
    }
    const validContact = validatePhoneNumber(structure.contact);
    if (!validContact && 0) {
      formErrors = "Invalid contact number";
    }

    if (formErrors) {
      setErrors(formErrors);
      return;
    }

    const { requires } = tags.find((r) => r.id === structure.role);

    const inputStructure = requires.reduce((result, item) => {
      if (item === "contact") {
        result.contact = "3";
      } else if (item !== "role") {
        const options = item.split("|");

        options.forEach((option) => {
          if (structure[option]) {
            result.structureCode = structure[option];
            result.structureType = STRUCTURE_MAP[option];
          }
        });
      }

      return result;
    }, {});

    console.log();

    setLoading(true);
    addGotvRole({
      variables: {
        input: {
          voterId: voter.id,
          role: structure.role,
          ...inputStructure,
        },
      },
    })
      .then(({ data }) => {
        if (data.addGotvRole.success) {
          setSearch(false);
          setLoading(false);
          setVoter(null);
          setValues({});

          message.success("User Added");
        } else {
          message.error(data.addGotvRole.error);
          setErrors(formErrors);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  console.log(structure, values);

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button type="primary" onClick={() => toggleVisible(!visible)}>
          Assign User
        </Button>
      </Flex>
      <Modal
        title="Assign User"
        visible={visible}
        footer={null}
        onCancel={() => toggleVisible(!visible)}
      >
        <Button onClick={() => onReset()}>Search Again</Button>
        {!voter ? (
          <>
            {!showSearch ? (
              <PersonSearch
                values={{ ...values, ...structure }}
                onChange={onChange}
                onSearch={() => setSearch(true)}
                structures={structures}
                components={{
                  constituency: { code: "id" },
                  region: { code: "id" },
                }}
              />
            ) : null}
            {showSearch ? (
              <>
                <Box sx={{ overflow: "scroll", maxHeight: "300px" }}>
                  <ResultsContainer
                    useWindow={false}
                    onItemClick={(item) => {
                      setVoter(item);
                      onSetStructure({
                        contact: item.contact.length
                          ? item.contact[0].value
                          : "",
                      });
                    }}
                    icon={"circle"}
                    search={values}
                  />
                </Box>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Card>
              <Card.Meta
                title={`${voter.firstName} ${voter.lastName}`}
                description={`${voter.registrationNumber}`}
              />
            </Card>
            <Form.Item label="Cellphone Number">
              <Input
                value={structure.contact}
                onChange={(e) => onSetStructure({ contact: e.target.value })}
              />
            </Form.Item>
            <StructuresComponent
              onSelect={onSetStructure}
              value={structure}
              structures={structures}
              formRoles={tags}
              vertical
              components={{
                region: { code: "id" },
                constituency: { code: "id" },
              }}
            />
            {errors ? (
              <Box mb={[2]}>
                <Alert type="error" message={errors} />
              </Box>
            ) : null}
            <Button
              type="primary"
              onClick={onAddUser}
              disabled={!structure.role || loading}
            >
              Save
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

const ADD_GOTV_ROLE = gql`
  mutation addGotvROleUser($input: AddGotvInput) {
    addGotvRole(input: $input) {
      success
      error
    }
  }
`;

export default AddGotvRole;
