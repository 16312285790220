import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Row, Spin } from "antd";
import { Box } from "rebass";
import CanvasserMap from "./CanvasserMap";

const Canvasser = (props) => {
  const { loading, error, data } = useQuery(CANVASSER, {
    variables: {
      input: props.query,
    },
  });

  if (error) return `Error! ${error.message}`;

  if (loading || !data)
    return (
      <Box p={[10]} sx={{ textAlign: "center" }}>
        <Spin />
      </Box>
    );

  const { getCanvasCountsByCanvasser } = data;

  const columns = [
    {
      title: "Canvasser Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Canvasser Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.count - b.count,
    },
  ];

  return (
    <Row>
      <CanvasserMap
        key={new Date()}
        {...props}
        structure={props.query}
        data={getCanvasCountsByCanvasser}
        columns={columns}
      />
    </Row>
  );
};

export default Canvasser;

const CANVASSER = gql`
  query getCanvasCountsByCanvasser($input: ReportingQueryInput) {
    getCanvasCountsByCanvasser(input: $input) {
      count
      firstName
      lastName
      id
    }
  }
`;
