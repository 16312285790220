import React, { useRef, useState } from "react";
import {
  Autocomplete,
  useJsApiLoader,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import { Flex, Input, Switch, Typography, notification } from "antd";

export default function AutoSearchComponent({ onSetLocation, showMap = true }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: ["places"],
    googleMapsApiKey: "AIzaSyDQAFvpRwX0bb5fM0UmFnnFr6xj10E3eKU", // Replace with your actual API key
  });
  const [zoomLevel, setZoomLevel] = useState(5);
  const mapRef = useRef(null);

  const onMapLoad = (map) => {
    mapRef.current = map;
  };
  const onMapZoomChanged = () => {
    if (mapRef.current) {
      setZoomLevel(mapRef.current.getZoom());
    }
  };
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const [searchBoxRef, setSearchBoxRef] = useState();
  const [inputValue, setInputValue] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: -29.1, lng: 26.2 });

  const [marker, setMarker] = useState(null);

  const onPlaceChanged = () => {
    if (searchBoxRef) {
      const place = searchBoxRef.getPlace();
      onSetLocation(place);
      setInputValue(place.formatted_address);

      if (place.geometry) {
        const location = place.geometry.location;
        const latLng = { lat: location.lat(), lng: location.lng() };

        setMarker(latLng);
        setMapCenter(latLng);
        setZoomLevel(18);
      }
    } else {
      openNotificationWithIcon(
        "warning",
        "Loading",
        "Autocomplete is not loaded yet!"
      );
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const onMapClick = async (event) => {
    if (zoomLevel >= 15) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMarker({ lat, lng });

      const geocoder = new window.google.maps.Geocoder();
      try {
        const results = await geocoder.geocode({ location: { lat, lng } });

        if (results.results.length) {
          onSetLocation(results.results[0]);
          setInputValue(results.results[0].formatted_address);
        } else {
          openNotificationWithIcon(
            "warning",
            "Location Not Found",
            "No address found at the selected location."
          );
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Error in reverse geocoding: " + error.message
        );
      }
    } else {
      openNotificationWithIcon("warning", "Note", "Zoom in to place marker");
    }
  };

  return (
    <div>
      {isLoaded ? (
        <div style={{ marginBottom: "16px" }}>
          <Autocomplete
            onLoad={setSearchBoxRef}
            onPlaceChanged={onPlaceChanged}
            options={{ componentRestrictions: { country: "za" } }}
          >
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search for places"
            />
          </Autocomplete>
          <Typography>
            Zoom in and click map to place pin to geocode address
          </Typography>
        </div>
      ) : null}
      {isLoaded && showMap ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          onLoad={onMapLoad}
          onZoomChanged={onMapZoomChanged}
          onClick={onMapClick}
          zoom={zoomLevel}
          options={{ disableDoubleClickZoom: true }}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}
