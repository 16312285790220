import React, { useContext } from "react";
import { Menu } from "antd";
import { navigate } from "@reach/router";
import { PrivateRoleRoute, PrivateStructureRoute, PrivateRoute } from "../App";
import gql from "graphql-tag";
import { authContext } from "../modules/Auth/Auth";

export const GET_VIEWER_GOTV = gql`
  {
    viewer {
      gotv {
        code
        type
        role
      }
    }
  }
`;
const SideDrawer = () => {
  const { setUnauthStatus, setApplicationToken } = useContext(authContext);

  const onLogout = (e) => {
    setApplicationToken(null);
    setUnauthStatus();

    navigate("/login");
  };

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      style={{
        flex: 1,
        minWidth: 0,
      }}
    >
      <PrivateRoute path="/search" as={React.Fragment} key="search">
        <Menu.Item onClick={() => navigate("/search")}>Canvass</Menu.Item>
      </PrivateRoute>

      <PrivateRoute path="/lookup" as={React.Fragment} key="lookup">
        <Menu.Item onClick={() => navigate("/lookup")}>Lookup</Menu.Item>
      </PrivateRoute>

      <PrivateRoleRoute
        key={`${new Date()}-admin-${Math.random()}`}
        role={"Admin"}
        as={React.Fragment}
      >
        <Menu.Item
          onClick={() => navigate("/admin/users")}
          block
          size="large"
          type="primary"
        >
          Admin
        </Menu.Item>
      </PrivateRoleRoute>

      <PrivateRoleRoute
        key={`${new Date()}-reporting-${Math.random()}`}
        role={"Reporting"}
        as={React.Fragment}
      >
        <PrivateStructureRoute
          key={`${new Date()}-admin`}
          as={React.Fragment}
          render={(structure) => {
            return (
              <Menu.Item
                onClick={() =>
                  navigate(
                    `/reporting/${structure.type.toLowerCase()}/${
                      structure.code
                    }`
                  )
                }
                block
                size="large"
                type="primary"
              >
                Reporting
              </Menu.Item>
            );
          }}
        />
      </PrivateRoleRoute>
      <Menu.Item onClick={onLogout} block size="large" type="primary">
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default SideDrawer;
