import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

const Marker = () => (
  <img
    src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png"
    alt="marker"
  />
);

const GoogleMapComponent = () => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [lastUpdate, setLastUpdate] = useState("");
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        onLocationChange(latitude, longitude);
      },
      (err) => {
        console.error(err);
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, []);

  const onLocationChange = (lat, lng) => {
    const updateTime = new Date().toLocaleTimeString();
    setLastUpdate(updateTime);
    console.log(
      `Location changed to latitude: ${lat}, longitude: ${lng} at ${updateTime}`
    );
  };

  const onMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setCenter({ lat: newLat, lng: newLng });
    onLocationChange(newLat, newLng);
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDD-sH7us86DAIOVBnDf4gBlunje6dcXr4" }}
        defaultCenter={{ lat: 0, lng: 0 }}
        center={center}
        defaultZoom={11}
        zoom={zoom}
        onZoomAnimationEnd={(newZoom) => setZoom(newZoom)}
      >
        <Marker lat={center.lat} lng={center.lng} onDragEnd={onMarkerDragEnd} />
      </GoogleMapReact>

      <div>Last update time: {lastUpdate}</div>
    </div>
  );
};

export default GoogleMapComponent;
