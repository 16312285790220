import React, { useState } from "react";
import { Spin, Button, Modal, Card, Alert } from "antd";
import { useMutation, useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { Flex, Box } from "rebass";

import { validateForm, getFormattedNumber } from "../../common/utils";
import FormContainer from "../Form/FormContainer";
import config from "../../common/config";

export const formatValues = (values, tags) => {
  let obj = {
    tags: [],
    comments: [],
  };
  Object.keys(values).forEach((val) => {
    if (val.startsWith("tag")) {
      obj.tags = [...obj.tags, ...values[val]];
    } else if (val.startsWith("select")) {
      obj.tags = [...obj.tags, values[val]];
    } else if (["pd"].includes(val)) {
      obj[val] = parseInt(values[val]);
    } else if (["comments"].includes(val)) {
      obj.comments = Object.keys(values[val]).reduce((result, item) => {
        const tag = tags.find((tag) => tag.id === item);

        result.push(`${tag.code} - ${tag.name}: ${values[val][item]}`);

        return result;
      }, []);
    } else if (val === "cellphone") {
      obj.contact = getFormattedNumber(
        `${values[val].replace(/[^0-9]/g, "")}`,
        config[process.env.REACT_APP_SITE_ID].country.iso
      );
    } else {
      obj[val] = values[val];
    }
  });

  return obj;
};

const AffiliationModal = (props) => {
  const voter = props.voter;
  const [step, setStep] = useState(0);
  const [errors, setError] = useState({});
  const [updateVoter] = useMutation(UPDATE_VOTER);
  const [values, setValues] = useState({
    contact: "",
    comments: {},
  });
  const [changed, setChanged] = useState(false);
  const { loading, error, data } = useQuery(GET_CANVASS_OPTIONS);

  if (error) return <p>{error.message}</p>;
  if (loading) return <Spin />;

  if (!voter) return null;
  const { tags, canvassForm: steps = [] } = data;

  const currentStep = steps[step];

  const formEvents = (step, form) => {
    setError({});

    const validationErrors = validateForm(form, values);

    if (Object.keys(validationErrors).length) {
      setError(validationErrors);
      return;
    }

    switch (step) {
      case steps.length - 1:
        updateVoter({
          variables: {
            voterId: props.voter.registrationNumber,
            input: formatValues(values, tags),
          },
        }).then(() => {
          setValues({
            contact: "",
            comments: {},
          });
          props.onClose(true);
        });

        break;
      default:
      case 2:
      case 0: {
        setStep(step + 1);
        break;
      }
    }
  };

  const onNext = () => {
    const { fields } = currentStep;
    formEvents(step, fields);
  };

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <Modal
      title="Voter Identification"
      visible={props.visible}
      footer={null}
      onCancel={() => props.onClose(true)}
    >
      <Card>
        <Card.Meta
          title={`${voter.firstName} ${voter.lastName}`}
          description={null}
        />
      </Card>
      <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
        <FormContainer
          errors={errors}
          form={{
            fields: currentStep.fields.map((field) => {
              if (field.type === "inputRadio") {
                const [, fieldParam] = field.fieldId.split(".");
                return {
                  ...field,
                  tags: tags.filter((tag) => tag.code === fieldParam),
                };
              }

              if (field.type === "select") {
                return {
                  ...field,
                  error: errors[field.fieldId],
                  options:
                    field.options &&
                    field.options.map((option) => {
                      const taggedOption = tags.find(
                        (tag) => tag.name === option.value
                      );

                      if (taggedOption) {
                        return {
                          ...option,

                          id: taggedOption.id,
                        };
                      }
                      return option;
                    }),
                };
              }

              return field;
            }),
          }}
          values={values}
          onSetValues={onSetValues}
          onCommentSet={() => {}}
        />
        <Box pt={[4]}>
          {Object.keys(errors).length
            ? Object.keys(errors).map((e) => (
                <Alert message={errors[e]} type="error" />
              ))
            : null}
        </Box>
        <Flex py={[4]}>
          <Button
            style={{ marginRight: "10px" }}
            block
            type={"primary"}
            size="large"
            onClick={onNext}
          >
            {steps.length - 1 === step ? "Submit" : "Next"}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default AffiliationModal;

const GET_CANVASS_OPTIONS = gql`
  query getCanvassOptions {
    tags {
      name
      category
      code
      id
      hasComment
      commentDescription
      description
    }

    canvassForm(formId: "affForm") {
      title
      fields {
        type
        fieldId
        label
        placeholder
        required
        validation

        multiple
        sort
        limit
        options {
          label
          value
        }
      }
    }
  }
`;

export const UPDATE_VOTER = gql`
  mutation updateVoter($voterId: String!, $input: UpdateVoterInput!) {
    updateVoter(voterId: $voterId, input: $input) {
      id
      lastAffiliation
    }
  }
`;
