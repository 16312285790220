import React from "react";
import { Input, Form, Select } from "antd";
import TagList from "../../components/TagList/TagList";
import { Flex, Box } from "rebass";
import Location from "../../components/Grid/Location/Location";

const FormContainer = ({
  form,
  values = {},
  errors = {},
  onCommentSet,
  onSetValues,
}) => {
  const getFormComponent = (field) => {
    switch (field.type) {
      case "location":
        return (
          <Location
            {...field}
            value={values[field.fieldId] || ""}
            onChange={(value) => onSetValues({ [field.fieldId]: value })}
          />
        );
      case "inputRadio":
        return (
          <TagList
            {...field}
            value={values[field.fieldId] || ""}
            onChange={(value) => onSetValues({ [field.fieldId]: value })}
            onCommentSet={(id, value) => onCommentSet(id, value)}
            comments={values.comments}
          />
        );

      case "select":
        return (
          <Form.Item label={field.label} style={{ minWidth: "150px" }}>
            <Select
              style={{ width: "100%" }}
              value={values[field.fieldId] || ""}
              onChange={(value) => onSetValues({ [field.fieldId]: value })}
            >
              {field.options.map((option) => (
                <Select.Option value={option.id || option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            {errors[field.fieldId] ? errors[field.fieldId] : null}
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item label={field.label}>
            <Input.TextArea
              label={field.label}
              placeholder={field.placeholder}
              error={errors[field.fieldId]}
              fieldId={field.fieldId}
              onChange={(e) => onSetValues({ [field.fieldId]: e.target.value })}
              value={values[field.fieldId]}
              {...field}
            />
            {errors[field.fieldId] ? errors[field.fieldId] : null}
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={field.label}>
            <Input
              label={field.label}
              placeholder={field.placeholder}
              error={errors[field.fieldId]}
              fieldId={field.fieldId}
              value={values[field.fieldId]}
              onChange={(e) => onSetValues({ [field.fieldId]: e.target.value })}
              {...field}
            />
            {errors[field.fieldId] ? errors[field.fieldId] : null}
          </Form.Item>
        );
    }
  };

  return (
    <Form layout="vertical">
      <Flex flexWrap="wrap">
        {form.fields.map((field) => {
          return (
            <Box width={field.span || "100%"}>{getFormComponent(field)}</Box>
          );
        })}
      </Flex>
    </Form>
  );
};

export default FormContainer;
