import React from "react";
import { Box, Heading, Flex } from "rebass";
import { Spin, Button, Typography, Divider, Alert } from "antd";

import GotvContainer from "../../Gotv/GotvContainer";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";

import { LabelContainer } from "../../Voter/VoterProfileScreen";

const { Text } = Typography;

const CALL_STATUSES = {
  busy: "BUSY",
  pending: "PENDING",
  skipped: "SKIPPED",
  voted: "VOTED",
  votingLater: "VOTING_LATER",
  wont_vote: "WONT_VOTE",
};

const CallingStructure = ({ structure, electionCode }) => {
  const { loading, data, refetch } = useQuery(GET_VOTER_TO_CALL, {
    variables: {
      electionCode: parseInt(electionCode),
      input: {
        ...structure,
      },
    },
  });

  const [updateVoterCall, { loading: updateCallLoader }] =
    useMutation(UPDATE_STATUS);

  const onSubmit = async (status) => {
    await updateVoterCall({
      variables: {
        status,
        registrationNumber: data
          ? data.getVoterToCall.registrationNumber
          : null,
      },
    });
    refetch();
  };

  if (loading || !data || updateCallLoader) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );
  }

  const { getVoterToCall } = data;

  return (
    <>
      {!getVoterToCall.mobile ? (
        <Flex justifyContent="center" alignItems="center" m={[3]}>
          <Alert message="No voter found to call" type="warning" />
        </Flex>
      ) : (
        <>
          <Box mt={[3]} mx="auto" px={[3]}>
            <Heading mb={[3]} size="h4">
              Calling Voter
            </Heading>

            {getVoterToCall.firstName && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">First name</Text>
                </div>
                <div>
                  <Text color="#777777">{getVoterToCall.firstName}</Text>
                </div>
              </LabelContainer>
            )}
            {getVoterToCall.lastName && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Last name</Text>
                </div>
                <div>
                  <Text color="#777777">{getVoterToCall.lastName}</Text>
                </div>
              </LabelContainer>
            )}
            {getVoterToCall.registrationNumber && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Registration number</Text>
                </div>
                <div>
                  <Text color="#777777">
                    {getVoterToCall.registrationNumber}
                  </Text>
                </div>
              </LabelContainer>
            )}

            {getVoterToCall.phoneNumbers && getVoterToCall.phoneNumbers.length
              ? getVoterToCall.phoneNumbers.map((number, index) => {
                  return (
                    <LabelContainer>
                      <div>
                        <Text fontWeight="bold">Telephone {index + 1}</Text>
                      </div>
                      <div>
                        <Button
                          block
                          type="primary"
                          as="a"
                          href={`tel:${number}`}
                        >
                          Dial {number}
                        </Button>
                      </div>
                    </LabelContainer>
                  );
                })
              : null}
            {getVoterToCall.email && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Email</Text>
                </div>
                <div>
                  <Text color="#777777">{getVoterToCall.email}</Text>
                </div>
              </LabelContainer>
            )}
            {getVoterToCall.pd && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">PD</Text>
                </div>
                <div>
                  <Text color="#777777">{getVoterToCall.pd}</Text>
                </div>
              </LabelContainer>
            )}
            {getVoterToCall.issues && (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Issues</Text>
                </div>
                <div>
                  <Text color="#777777">{getVoterToCall.issues}</Text>
                </div>
              </LabelContainer>
            )}
            {getVoterToCall.tags.length
              ? getVoterToCall.tags.map((tag) => {
                  return (
                    <LabelContainer>
                      <Text color="#777777">{tag.name}</Text>
                    </LabelContainer>
                  );
                })
              : null}
          </Box>
          <Box>
            <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
              <Button
                block
                size="large"
                style={{ backgroundColor: "#1b8b3f", color: "white" }}
                onClick={() => onSubmit(CALL_STATUSES.voted)}
              >
                Voted
              </Button>
            </Box>
            <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
              <Button
                block
                size="large"
                style={{ backgroundColor: "#f58945", color: "white" }}
                onClick={() => onSubmit(CALL_STATUSES.votingLater)}
              >
                Voting Later
              </Button>
            </Box>
            <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
              <Button
                block
                size="large"
                style={{ backgroundColor: "#e73232", color: "white" }}
                onClick={() => onSubmit(CALL_STATUSES.wont_vote)}
              >
                Won't Vote
              </Button>
            </Box>
            <Divider />

            <Flex>
              <Box width={1 / 2} p={3}>
                <Button
                  block
                  type="primary"
                  as="a"
                  href={`tel:${getVoterToCall.mobile}`}
                >
                  Dial
                </Button>
              </Box>

              <Box width={1 / 2} p={3}>
                <Button
                  type="text"
                  style={{ width: "100%" }}
                  onClick={() => onSubmit(CALL_STATUSES.skipped)}
                >
                  Skip
                </Button>
              </Box>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
};

const CallingApp = ({ electionCode }) => {
  return (
    <GotvContainer
      components={{ constituency: { code: "code" }, pd: { code: "id" } }}
    >
      {(structure) => (
        <CallingStructure structure={structure} electionCode={electionCode} />
      )}
    </GotvContainer>
  );
};

export default CallingApp;

const GET_VOTER_TO_CALL = gql`
  query getVoter($electionCode: Int, $input: ReportingQueryInput) {
    getVoterToCall(electionCode: $electionCode, input: $input) {
      tags {
        name
        code
      }
      registrationNumber
      firstName
      lastName
      mobile
      status
      assignedUser
      phoneNumbers
      email
      issues
      pd
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation updateVoterCall(
    $status: VoterCallStatus
    $registrationNumber: String
  ) {
    updateVoterCallStatus(
      status: $status
      registrationNumber: $registrationNumber
    )
  }
`;
