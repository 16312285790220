import React, { useContext, useState } from "react";
import { navigate } from "@reach/router";
import { Spin, Form, Input, Button, DatePicker, Divider, Card } from "antd";
import { useQuery } from "react-apollo";

import { ResultsContext } from "./ResultsProvider";

import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";
import Title from "antd/es/typography/Title";
import { parsePhoneNumber } from "libphonenumber-js";
import AutoSearchComponent from "../../components/Autocomplete";
import useCheckVoterRegistration from "../../components/CreateVoter";
import { PoweroffOutlined, SearchOutlined } from "@ant-design/icons";

const SearchScreen = () => {
  const { setSearch } = useContext(ResultsContext);
  const { loading: searchLoading, data } = useQuery(GET_SEARCH_OPTIONS);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    registrationNumber: "",
  });
  const [loading] = useState(false);

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onSearch = () => {
    // validate the form here before going to results
    const { contact, dob, ...args } = values;
    const inputs = {
      ...args,
      contact: values.contact
        ? parsePhoneNumber(values.contact, "ZA").number
        : "",
      dob: dob ? dob : null,
    };
    setSearch(inputs);

    navigate("/results");
  };

  if (searchLoading || !data) return <Spin />;

  const {
    viewer: { structures },
  } = data;

  return (
    <AdminScreen title={"Canvass"} isLoading={loading}>
      <PersonSearch
        structures={structures}
        onChange={onSetValues}
        values={values}
        onSearch={onSearch}
      />
    </AdminScreen>
  );
};

export const PersonSearch = ({
  values,
  onChange,
  onSearch,
  structures,
  components = {},
}) => {
  const component_structures = (structure) => ({
    national: {
      countries: [
        {
          code: config[process.env.REACT_APP_SITE_ID].country.code,
          name: config[process.env.REACT_APP_SITE_ID].country.name,
        },
      ],
      provinces: [],
      municipalities: [],
      pollingDivisions: [],
    },
    provinces: {
      provinces: structure.provinces,
      municipalities: [],
      pollingDivisions: [],
    },
    municipalities: {
      provinces: [],
      municipalities: structure.municipalities,
      pollingDivisions: [],
    },
    pollingDivisions: {
      provinces: [],
      municipalities: [],
      pollingDivisions: structure.pollingDivisions,
    },
  });

  return (
    <>
      <Card
        title="Search Voters"
        extra={
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              navigate("/lookup");
            }}
          >
            Voter Lookup
          </Button>
        }
      >
        <Form layout="vertical">
          <StructuresComponent
            onSelect={(value) => onChange(value)}
            value={values}
            exclude={["RegionComponent", "ElectoralComponent"]}
            defaultRole={"CANVASSER"}
            structures={structures}
            components={components}
          />
          <Title level={5}>Voter Search</Title>
          <Divider />
          <Form.Item label={config[process.env.REACT_APP_SITE_ID].voterId}>
            <Input
              placeholder={`Enter ${
                config[process.env.REACT_APP_SITE_ID].voterId
              }`}
              value={values.registrationNumber}
              onChange={(e) => onChange({ registrationNumber: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="First Name">
            <Input
              placeholder="Search by first name"
              value={values.firstName}
              onChange={(e) => onChange({ firstName: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Last Name" style={{ fontWeight: 700 }}>
            <Input
              placeholder="Search by last name"
              value={values.lastName}
              onChange={(e) => onChange({ lastName: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Date of Birth" style={{ fontWeight: 700 }}>
            <DatePicker
              onChange={(_, dateString) => onChange({ dob: dateString })}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Cellphone Number">
            <Input
              value={values.contact}
              onChange={(e) => onChange({ contact: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              size="large"
              htmlType="submit"
              className="login-form-button"
              onClick={onSearch}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export const VoterRegister = () => {
  const { checkVoterRegistration } = useCheckVoterRegistration();

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    idnumber: "",
    contact: "",
    address: null,
  });

  const [loading, setLoading] = useState(false);

  const onChange = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onSearch = async () => {
    setLoading(true);
    // validate the form here before going to results
    const { contact, address, ...args } = values;

    const addressComponents = address.address_components;
    const mappedAddress = {
      wtw: address.place_id,
    };

    addressComponents.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          mappedAddress.buildingNumber = component.long_name;
          break;
        case "locality": // Typically represents the city
          //mappedAddress.city = component.long_name;
          break;
        case "route":
          mappedAddress.addressLine1 = component.long_name;
          break;
        case "sublocality_level_1":
          mappedAddress.suburb = component.long_name;
          break;
        case "administrative_area_level_1":
          mappedAddress.state = component.long_name;
          break;
        case "country":
          mappedAddress.country = component.long_name;
          break;
        case "postal_code":
          mappedAddress.postalCode = component.long_name;
          break;
        // Add other case mappings as needed
      }
    });

    // Optional: Combine street number and route for full address line
    if (mappedAddress.buildingNumber && mappedAddress.addressLine1) {
      mappedAddress.addressLine1 = `${mappedAddress.buildingNumber} ${mappedAddress.addressLine1}`;
    }

    const input = {
      ...args,
      address: mappedAddress,
      contact: contact ? parsePhoneNumber(contact, "ZA").number : "",
    };

    await checkVoterRegistration({
      variables: {
        input,
      },
    });

    setLoading(false);
  };

  if (loading) return <Spin />;

  return (
    <Form layout="vertical">
      <Title level={5}>Enter Voter Details</Title>
      <Divider />
      <Form.Item label={config[process.env.REACT_APP_SITE_ID].voterId}>
        <Input
          placeholder={`Enter ${config[process.env.REACT_APP_SITE_ID].voterId}`}
          value={values.idnumber}
          onChange={(e) => onChange({ idnumber: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="First Name">
        <Input
          placeholder="Enter first name"
          value={values.firstName}
          onChange={(e) => onChange({ firstName: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Last Name" style={{ fontWeight: 700 }}>
        <Input
          placeholder="Enter last name"
          value={values.lastName}
          onChange={(e) => onChange({ lastName: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Cellphone Number">
        <Input
          placeholder="Enter cellphone number"
          value={values.contact}
          onChange={(e) => onChange({ contact: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Home Address">
        <AutoSearchComponent onSetLocation={(e) => onChange({ address: e })} />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          size="large"
          htmlType="submit"
          className="login-form-button"
          onClick={onSearch}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SearchScreen;
