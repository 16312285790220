import React from "react";
import { Spin, List, Card, Button } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import Icon from "@ant-design/icons/lib/components/Icon";
import { navigate } from "@reach/router";
import { EditOutlined, EyeFilled } from "@ant-design/icons";
import { VoterRegister } from "./LookupScreen";

const GET_VOTERS_QUERY = gql`
  query queryVoters($input: VoterSearchInput, $after: String, $first: Int) {
    voters(input: $input, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          firstName
          middleName
          lastName
          registrationNumber
          lastAffiliation
          contact(where: { type: "mobile" }, first: 1) {
            value
          }
          pollingDivision {
            id
            province {
              name
              code
            }
            constituency: municipality {
              name
              code
            }
            electoralDivision: ward {
              name
              code
            }
            pdCode: code
            pdName: name
            streams {
              surnames
              name
              location
              address
            }
          }
        }
      }
    }
  }
`;

const GET_VOTED_QUERY = gql`
  query queryVoters(
    $input: VoterSearchInput
    $after: String
    $first: Int
    $election: Int
  ) {
    voters(input: $input, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          firstName
          middleName
          lastName
          registrationNumber
          voted(where: { electionCode: $election }) {
            id
            voted
          }
          lastAffiliation
          pollingDivision {
            constituency {
              name
            }
            pdCode: code
          }
        }
      }
    }
  }
`;
const queries = {
  voted: {
    gql: GET_VOTED_QUERY,
    variables: ["election"],
  },
  voters: {
    gql: GET_VOTERS_QUERY,
    variables: [],
  },
};

const ResultsContainer = ({
  search,
  type = "voters",
  onItemClick,
  icon,
  useWindow = true,
  renderAction = null,
  ...props
}) => {
  const query = queries[type];

  const { data, loading, fetchMore } = useQuery(query.gql, {
    variables: {
      input: search,
      first: 20,
      ...query.variables.reduce((result, item) => {
        if (props[item]) {
          result[item] = props[item];
        }

        return result;
      }, {}),
    },
  });

  if (loading) return <Spin />;

  const {
    voters = {
      edges: [],
      pageInfo: { hasNextPage: false },
    },
  } = data ?? {};

  const onFetchMore = () => {
    fetchMore({
      variables: {
        input: search,
        after: voters.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        return {
          voters: {
            __typename: "Voters",
            pageInfo: fetchMoreResult.voters.pageInfo,
            edges: [
              ...previousResult.voters.edges,
              ...fetchMoreResult.voters.edges,
            ],
          },
        };
      },
    });
  };

  return (
    <Card title={"Results"}>
      {!voters.edges.length ? (
        <VoterRegister
          title="No voters found"
          description="Fill the the form to lookup the voter on the IEC database and create a new voter record"
        />
      ) : (
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={onFetchMore}
          hasMore={!loading && voters && voters.pageInfo.hasNextPage}
          useWindow={useWindow}
        >
          <List
            dataSource={voters.edges}
            renderItem={({ node: item }) => (
              <List.Item
                className="results-list-item"
                key={item.registrationNumber}
                extra={[
                  <Button
                    type="link"
                    icon={<EyeFilled />}
                    onClick={renderAction ? null : () => onItemClick(item)}
                  >
                    View
                  </Button>,
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={
                      renderAction ? null : () => onItemClick(item, "canvass")
                    }
                  >
                    Start Canvass
                  </Button>,
                ]}
                actions={renderAction ? [renderAction(item)] : []}
              >
                <List.Item.Meta
                  title={`${item.firstName} ${item.lastName} - ${item.registrationNumber}`}
                  description={[
                    {
                      title: "Polling Division",
                      value:
                        item.pollingDivision.pdName ||
                        item.pollingDivision.pdCode,
                    },
                    item.pollingDivision.electoralDivision && {
                      title: "Ward",
                      value: item.pollingDivision.electoralDivision.name,
                    },
                    {
                      title: "Municipality",
                      value: item.pollingDivision.constituency?.code ?? "-",
                    },
                    {
                      title: "Province",
                      value: item.pollingDivision.province.code,
                    },
                  ]
                    .map((v) => v.value)
                    .join(" | ")}
                >
                  {item.registrationNumber}
                </List.Item.Meta>
              </List.Item>
            )}
          >
            {loading && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      )}
    </Card>
  );
};

export default ResultsContainer;
