import { Spin } from "antd";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const LoadRegions = ({ national, render, ...rest }) => {
  let query = {};
  if (national) {
    query.country = national;
  }

  const { loading, data } = useQuery(GET_REGIONS, {
    variables: {
      country: national,
    },
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.regions);
};

export const LoadProvinces = ({ national, render, ...rest }) => {
  let query = {};
  if (national) {
    query.country = national;
  }

  const { loading, data } = useQuery(GET_PROVINCES, {
    variables: {
      country: national,
    },
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.provinces);
};

export const LoadMunicipalities = ({ province, render, ...rest }) => {
  let query = {};
  if (province) {
    query.province = province;
  }

  const { loading, data } = useQuery(GET_MUNICIPALITY, {
    variables: query,
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.municipalities);
};

export const LoadWards = ({ municipality, render }) => {
  let query = {};
  if (municipality) {
    query.municipality = municipality;
  }

  const { loading, data } = useQuery(GET_WARDS, {
    variables: query,
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.wards);
};

export const LoadConstituencies = ({ region, render }) => {
  let query = {};
  if (region) {
    query.region = parseInt(region);
  }
  const { loading, data } = useQuery(GET_CONSTITUENCIES, {
    variables: query,
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.constituencies);
};

export const LoadElectoralDivisions = ({ region, constituency, render }) => {
  const { loading, data } = useQuery(GET_ELECTORAL_DIVISIONS, {
    variables: {
      region: region ? parseInt(region) : null,
      constituency: constituency ? parseInt(constituency) : null,
    },
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.electoralDivisions);
};

const GET_REGIONS = gql`
  query getRegions($country: String) {
    regions(country: $country) {
      id
      code
      name
    }
  }
`;

const GET_ELECTORAL_DIVISIONS = gql`
  query getElectoralDivisions($region: Int, $constituency: Int) {
    electoralDivisions(region: $region, constituency: $constituency) {
      id
      code
      name
    }
  }
`;

const GET_CONSTITUENCIES = gql`
  query getConstituencies($region: Int) {
    constituencies(region: $region) {
      id
      code
      name
    }
  }
`;

const GET_PROVINCES = gql`
  query getProvinces($country: String) {
    provinces(country: $country) {
      id
      code
      name
    }
  }
`;

const GET_MUNICIPALITY = gql`
  query getMunicipalities($province: String) {
    municipalities(province: $province) {
      id
      code
      name
    }
  }
`;

const GET_WARDS = gql`
  query getWards($municipality: String) {
    wards(municipality: $municipality) {
      id
      code
      name
    }
  }
`;
