import React, { useContext, useState } from "react";
import { navigate, Redirect } from "@reach/router";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { useMutation } from "react-apollo";

import { gql } from "apollo-boost";
import { Box, Text } from "rebass";

import { authContext } from "./Auth";
import Alert from "../../components/Alert";
import config from "../../common/config";
import { useForm } from "antd/es/form/Form";

const CONFIRM_USER = gql`
  mutation confirmUser($data: ConfirmInput!) {
    confirm(data: $data) {
      token
      user {
        registrationNumber
        roles {
          role
          exclusions
          structures {
            type
            code
          }
        }
      }
    }
  }
`;

const ConfirmForm = (props) => {
  const [form] = useForm();
  const [confirmUser] = useMutation(CONFIRM_USER);
  const [loading, setLoading] = useState(false);
  const { setAuthStatus, application } = useContext(authContext);
  const [error, setError] = useState(null);

  if (!application) {
    return <Redirect to="/login" />;
  }

  const handleSubmit = (values) => {
    setLoading(true);

    confirmUser({
      variables: {
        data: {
          application,
          token: values.token,
        },
      },
    })
      .then(({ data }) => {
        setLoading(true);
        setAuthStatus(data.confirm);
        navigate("/search");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.graphQLErrors[0].message);
      });
  };

  return (
    <Row type="flex" justify="center" style={{ minHeight: "100vh" }}>
      <Col style={{ width: "100%", maxWidth: "500px" }}>
        <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
          <div className="app-logo">
            <img
              alt={"logo"}
              src={config[process.env.REACT_APP_SITE_ID].logo}
              className="app-logo"
              style={{
                maxWidth: "200px",
                height: "auto",
              }}
            />
          </div>
          {loading ? (
            <Spin />
          ) : (
            <Form
              onFinish={handleSubmit}
              className="login-form"
              form={form}
              layout="vertical"
            >
              <Box bg="#eee" p={[2]} mb={[2]}>
                <Text fontWeight="bold" textAlign="center">
                  Please check your sms or inbox for your one time pin. Be sure
                  to check your spam folder.
                </Text>
              </Box>
              <Form.Item
                label="One Time Pin (OTP)"
                name="token"
                rules={[
                  {
                    required: true,
                    message:
                      "Please your one time pin received by sms or email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          )}
          {error && <Alert error message={error} />}
        </Box>
      </Col>
    </Row>
  );
};

const ConfirmScreen = ConfirmForm;

export default ConfirmScreen;
