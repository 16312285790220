import React, { useContext, useState } from "react";
import { navigate } from "@reach/router";
import { Spin, Button, List, Card, Typography } from "antd";
import { useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Box, Flex } from "rebass";
import { ResultsContext } from "../Search/ResultsProvider";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { PrivateRoleRoute } from "../../App";
import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";
import { CHECK_VOTER_REGISTRATION_MUTATION } from "../../components/CreateVoter";
import LookupPollingStation from "../../components/Structures/LookupPollingStation";

export const LabelContainer = (props) => (
  <Flex
    {...props}
    py={[3]}
    flexDirection={["column", "column", "row"]}
    alignItems={["left", "left", "center"]}
    sx={{
      borderBottom: "1px solid #ddd",
      justifyContent: "space-between",
      maxHeight: "300px",
      overflow: "auto",
    }}
  >
    {props.children}
  </Flex>
);

const VoterProfileScreen = (props) => {
  const { setVoter } = useContext(ResultsContext);
  const [loader, setLoader] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_VOTER, {
    variables: { registrationNumber: props.registrationNumber },
  });

  const [fetchIec] = useMutation(CHECK_VOTER_REGISTRATION_MUTATION, {
    onCompleted: (data) => {
      if (data.updated) {
        refetch();
      } else if (data.error) {
      }
    },
  });

  if (error) return <p>{error}</p>;
  if (loading) return <Spin />;

  const { voter } = data;

  const onStartCanvass = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/canvass`);
  };

  const onStartContact = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/contact`);
  };

  const onStartCapture = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/capture`);
  };

  const onFetchIEC = async () => {
    setLoader(true);
    await fetchIec({
      variables: {
        input: {
          idnumber: data.voter.registrationNumber,
        },
      },
    });
    setLoader(false);
  };

  const groupedTags = groupBy(voter.tags, "category");

  const profile = [
    {
      title: "First Name",
      value: voter.firstName,
    },
    {
      title: "Last Name",
      value: voter.lastName,
    },
    {
      title: "Voter Status",
      value: voter.alias,
    },
    {
      title: "Gender",
      value: voter.gender,
    },
    {
      title: config[process.env.REACT_APP_SITE_ID].voterId,
      value: voter.voterId || voter.registrationNumber,
    },
  ];
  return (
    <AdminScreen
      title={`${voter.firstName} ${voter.lastName}`}
      onBack={() => navigate("/results")}
    >
      <Card title="Voter Details" style={{ marginBottom: "16px" }}>
        <List
          itemLayout="horizontal"
          dataSource={profile.filter((p) => !!p.value)}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
      </Card>
      <Card
        title="IEC Registered Polling Station"
        style={{ marginBottom: "16px" }}
        extra={[
          <PrivateRoleRoute
            key={`${new Date()}-admin`}
            role={"Canvassing"}
            as={React.Fragment}
          >
            <Box mb={[2]}>
              <Button
                disabled={loader}
                block
                type="primary"
                size="small"
                onClick={onFetchIEC}
              >
                {loader ? "Checking..." : "Check for update"}
              </Button>
            </Box>
          </PrivateRoleRoute>,
        ]}
      >
        {voter.pollingDivision ? (
          <List
            itemLayout="horizontal"
            dataSource={[
              {
                title: "Polling Division",
                value:
                  voter.pollingDivision.pdName || voter.pollingDivision.pdCode,
              },
              {
                title: "Province",
                value: voter.pollingDivision.province.name,
              },
              {
                title: "Municipality",
                value: voter.pollingDivision.constituency?.name ?? "-",
              },
              voter.pollingDivision.electoralDivision && {
                title: "Ward",
                value: voter.pollingDivision.electoralDivision.name,
              },
            ].filter((p) => !!p.value)}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta title={item.title} description={item.value} />
              </List.Item>
            )}
          />
        ) : (
          <Typography.Text strong>No register polling station</Typography.Text>
        )}
      </Card>
      <Card title="Voter Address Lookup" style={{ marginBottom: "16px" }}>
        <LookupPollingStation
          addresses={voter.addresses}
          voterId={voter.registrationNumber}
        />
      </Card>
      <Card
        title="Contact Information"
        style={{ marginBottom: "16px" }}
        extra={[
          <PrivateRoleRoute
            key={`${new Date()}-admin`}
            role={"Canvassing"}
            as={React.Fragment}
          >
            <Button size="small" type="primary" onClick={onStartContact}>
              Update Contact
            </Button>
          </PrivateRoleRoute>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={voter.contact.map((contact) => ({
            title: contact.type.toUpperCase(),
            value: contact.value,
          }))}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
      </Card>
      <Card title="Address Information" style={{ marginBottom: "16px" }}>
        <List
          itemLayout="horizontal"
          dataSource={
            voter.addresses?.map((c) => ({
              title: c.type.toUpperCase(),
              value: [
                c.addressLine1,
                c.addressLine2,
                c.suburb,
                c.state,
                c.postalCode,
              ]
                .filter((b) => !!b)
                .join(", "),
            })) ?? []
          }
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
      </Card>
      <Card
        title="Canvass Information"
        style={{ marginBottom: "16px" }}
        extra={[
          <PrivateRoleRoute
            key={`${new Date()}-admin`}
            role={"Canvassing"}
            as={React.Fragment}
          >
            <Box mb={[2]}>
              <Button
                block
                type="primary"
                size="small"
                onClick={onStartCanvass}
              >
                Start {config[process.env.REACT_APP_SITE_ID].canvass.label}
              </Button>
            </Box>
          </PrivateRoleRoute>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={[
            {
              title: "Affiliation",
              value: voter.lastAffiliation,
            },
            {
              title: "Tags",
              value: Object.keys(groupedTags)
                .map((p) => groupedTags[p][0].name)
                .join(", "),
            },
            {
              title: "Last Canvass",
              value: voter.updatedAt
                ? dayjs(voter.updatedAt).format("YYYY-MM-DD HH:mm")
                : null,
            },
          ]}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
      </Card>
      <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
        <Box py={[4]}>
          {config[process.env.REACT_APP_SITE_ID].capture ? (
            <PrivateRoleRoute
              key={`${new Date()}-admin`}
              role={"Canvassing"}
              as={React.Fragment}
            >
              <Box mb={[2]}>
                <Button
                  block
                  type="secondary"
                  size="large"
                  onClick={onStartCapture}
                >
                  {config[process.env.REACT_APP_SITE_ID].capture.label}
                </Button>
              </Box>
            </PrivateRoleRoute>
          ) : null}
        </Box>
      </Box>
    </AdminScreen>
  );
};

export default VoterProfileScreen;

export const CHECK_VOTER_RECORD = gql`
  mutation checkVoter($registrationNumber: String!) {
    checkVoterRegistration(idnumber: $registrationNumber) {
      success
      error
      updated
    }
  }
`;

export const UPDATE_VOTER_RECORD = gql`
  mutation updateVoter($voterId: String!, $input: UpdateVoterInput!) {
    updateVoter(voterId: $voterId, input: $input) {
      id
      firstName
      middleName
      lastName
      registrationNumber
      voterId
      alias
      gender
      comments {
        text
        createdAt
      }
      contact {
        id
        value
        type
      }
      tags {
        code
      }
      gender
      addresses {
        id
        addressLine1
        addressLine2
        suburb
        state
        type
        postalCode
        latitude
        longitude
      }
      updatedAt: modifiedAt
      profession
      pollingDivision {
        id
        province {
          name
        }
        constituency: municipality {
          name
        }
        electoralDivision: ward {
          name
        }
        pdCode: code
        pdName: name
        streams {
          surnames
          name
          location
          address
        }
      }
      tags {
        category
        name
      }
      lastAffiliation
    }
  }
`;

export const GET_VOTER = gql`
  query getVoter($registrationNumber: String!) {
    voter(registrationNumber: $registrationNumber) {
      id
      firstName
      middleName
      lastName
      registrationNumber
      voterId
      alias
      gender
      comments {
        text
        createdAt
      }
      contact {
        id
        value
        type
      }
      tags {
        code
      }
      gender
      addresses {
        id
        wtw
        addressLine1
        addressLine2
        suburb
        state
        type
        postalCode
        latitude
        longitude
      }
      updatedAt: modifiedAt
      profession
      pollingDivision {
        id
        province {
          name
        }
        constituency: municipality {
          name
        }
        electoralDivision: ward {
          name
        }
        pdCode: code
        pdName: name
        streams {
          surnames
          name
          location
          address
        }
      }
      tags {
        category
        name
      }
      lastAffiliation
    }
  }
`;
