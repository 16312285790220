import { Flex, Form, Select, Spin } from "antd";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const PollList = ({
  ward,
  municipality,
  pd,
  province,
  showTitle = true,
  code = "id",
  ...restProps
}) => {
  const query = {};

  if (municipality) {
    query.municipality = municipality;
  }

  if (province) {
    query.province = province;
  }

  if (ward) {
    query.ward = ward;
  }

  const { data, loading } = useQuery(GET_POLLS, {
    variables: {
      input: query,
    },
  });

  if (loading || !data)
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );

  const { polls } = data;

  return (
    <Form.Item
      label={showTitle ? "Polling Divisions" : null}
      style={{ minWidth: "250px" }}
    >
      <Select style={{ width: "100%" }} value={pd} {...restProps}>
        <Select.Option value={null}>All</Select.Option>
        {polls
          .sort((a, b) => (a.pdCode >= b.pdCode ? 1 : -1))
          .map((p) => (
            <Select.Option key={p[code]} value={p[code]}>
              PD {p.pdName || p.pdCode}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

const GET_POLLS = gql`
  query getPollingDivisions($input: PollingDivisionInput) {
    polls(input: $input) {
      id
      pdCode: code
      pdName: name
    }
  }
`;
