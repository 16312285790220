import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Empty, Row, Spin } from "antd";
import { Box } from "rebass";
import { groupBy } from "lodash";
import { BarGraph } from "./Graphs";
import CardComponent from "./ui/CardComponent";
import config from "../../common/config";
import PreviousResult from "./PreviousResult";

const Affiliation = (props) => {
  const { loading, error, data } = useQuery(AFFILIATION_COUNT, {
    variables: {
      input: props.query,
    },
  });
  if (error) return `Error! ${error.message}`;

  if (loading || !data)
    return (
      <Box p={[10]} sx={{ textAlign: "center" }}>
        <Spin />
      </Box>
    );

  const { getCanvassAffliationsCounts } = data;

  if (!getCanvassAffliationsCounts.length) {
    return (
      <Box style={{ width: "100%" }}>
        <Empty />
      </Box>
    );
  }

  const affiliationData = groupBy(
    getCanvassAffliationsCounts,
    (i) => i.structure.name
  );

  return (
    <Row>
      {affiliationData &&
        Object.keys(affiliationData).map((key) => {
          const total = affiliationData[key].reduce(
            (result, item) => (result += item.count),
            0
          );

          const [{ structure }] = affiliationData[key];

          return (
            <CardComponent
              title={`${props.title}: ${key}`}
              key={`${props.title}: ${key}`}
            >
              <BarGraph
                total={total}
                {...props}
                tags={props.tags}
                structure={key}
                key={new Date()}
                data={affiliationData[key]}
              />
            </CardComponent>
          );
        })}
    </Row>
  );
};

export default Affiliation;

const AFFILIATION_COUNT = gql`
  query getCanvassAffliationsCounts($input: ReportingQueryInput) {
    getCanvassAffliationsCounts(input: $input) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
  }
`;
