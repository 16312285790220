import React, { useRef, useState, useEffect } from "react";
import {
  Autocomplete,
  useJsApiLoader,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import {
  Button,
  Flex,
  Input,
  List,
  Radio,
  Space,
  Typography,
  notification,
} from "antd";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { UPDATE_VOTER_RECORD } from "../../modules/Voter/VoterProfileScreen";

export default function LookupPollingStation({
  addresses: allAddresses,
  voterId,
}) {
  const addresses = allAddresses.filter((a) => a.latitude && a.longitude);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: ["places"],
    googleMapsApiKey: "AIzaSyDQAFvpRwX0bb5fM0UmFnnFr6xj10E3eKU",
  });

  const [zoomLevel, setZoomLevel] = useState(5);
  const [loading, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: -29.1, lng: 26.2 });
  const [marker, setMarker] = useState(null);
  const [result, setResult] = useState(null);
  const mapRef = useRef(null);
  const [searchBoxRef, setSearchBoxRef] = useState();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [showAddButton, setShowAddButton] = useState(false);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  const [updateVoter] = useMutation(UPDATE_VOTER_RECORD);
  const [checkPollingStation] = useMutation(CHECK_POLLING_STATION);

  const onFindVotingStation = async (latitude, longitude) => {
    try {
      const { data } = await checkPollingStation({
        variables: { latitude, longitude },
      });
      if (data && data.checkPollingStation) {
        setResult(data.checkPollingStation);
      }
    } catch (error) {
      console.error("Error finding polling station:", error);
      // Handle error
    }
  };

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const onMapZoomChanged = () => {
    if (mapRef.current) {
      setZoomLevel(mapRef.current.getZoom());
    }
  };

  const onPlaceChanged = () => {
    if (searchBoxRef) {
      const place = searchBoxRef.getPlace();
      setResult(null);
      setInputValue(place.formatted_address);

      const isInAddresses = addresses.some(
        (addr) => addr.wtw === place.place_id
      );
      setShowAddButton(!isInAddresses);

      if (!isInAddresses) {
        setNewAddress(place);
      }

      if (place.geometry) {
        const location = place.geometry.location;
        const latLng = { lat: location.lat(), lng: location.lng() };

        setMarker(latLng);
        setMapCenter(latLng);
        setZoomLevel(18);

        onFindVotingStation(latLng.lat, latLng.lng);
      }
    } else {
      openNotificationWithIcon(
        "warning",
        "Loading",
        "Autocomplete is not loaded yet!"
      );
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddressChange = (e) => {
    const address = addresses.find((addr) => addr.id === e.target.value);
    setSelectedAddress(address);
    setResult(null);
    handleSelectAddress(address);
  };

  const handleSelectAddress = (address) => {
    setInputValue(address.addressLine1);
    const location = { lat: address.latitude, lng: address.longitude };
    setMarker(location);
    setMapCenter(location);
    setZoomLevel(10);

    onFindVotingStation(address.latitude, address.longitude); // Find voting station
  };

  const onAddAddress = async () => {
    setLoading(true);
    // validate the form here before going to results

    const addressComponents = newAddress?.address_components ?? [];
    const mappedAddress = {
      wtw: newAddress?.place_id ?? null,
    };

    addressComponents.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          mappedAddress.buildingNumber = component.long_name;
          break;
        case "locality": // Typically represents the city
          //mappedAddress.city = component.long_name;
          break;
        case "route":
          mappedAddress.addressLine1 = component.long_name;
          break;
        case "sublocality_level_1":
          mappedAddress.suburb = component.long_name;
          break;
        case "administrative_area_level_1":
          mappedAddress.state = component.long_name;
          break;
        case "country":
          mappedAddress.country = component.long_name;
          break;
        case "postal_code":
          mappedAddress.postalCode = component.long_name;
          break;
        // Add other case mappings as needed
      }
    });

    // Optional: Combine street number and route for full address line
    if (mappedAddress.buildingNumber && mappedAddress.addressLine1) {
      mappedAddress.addressLine1 = `${mappedAddress.buildingNumber} ${mappedAddress.addressLine1}`;
    }

    if (newAddress.geometry) {
      const location = newAddress.geometry.location;

      mappedAddress.latitude = location.lat();
      mappedAddress.longitude = location.lng();
    }

    const input = {
      address: newAddress ? mappedAddress : null,
    };

    await updateVoter({
      variables: {
        voterId,
        input,
      },
    });

    setLoading(false);
    setNewAddress(null);
    setInputValue("");
    setShowAddButton(false);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  return (
    <div>
      {isLoaded ? (
        <div style={{ marginBottom: "16px" }}>
          <div style={{ marginBottom: "16px" }}>
            <Typography.Title level={5}>Voter Addresses</Typography.Title>
            <Typography.Text>
              Select or add an address to check polling station
            </Typography.Text>
            <div style={{ marginTop: "16px" }}>
              <Radio.Group
                onChange={handleAddressChange}
                value={selectedAddress?.id}
              >
                <Space direction="vertical">
                  {addresses.map((address, index) => (
                    <Radio key={index} value={address.id}>
                      {[
                        address.addressLine1,
                        address.addressLine2,
                        address.suburb,
                        address.state,
                        address.postalCode,
                      ]
                        .filter((b) => !!b)
                        .join(", ")}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </div>
          <Autocomplete
            onLoad={setSearchBoxRef}
            onPlaceChanged={onPlaceChanged}
            options={{ componentRestrictions: { country: "za" } }}
          >
            <Input.Group compact>
              <Input
                style={{ width: "calc(100% - 200px)" }}
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search for places"
              />
              <Button
                type="primary"
                size="middle"
                disabled={!showAddButton || loading}
                onClick={onAddAddress}
              >
                {loading ? "Adding..." : "Add Address"}
              </Button>
            </Input.Group>
          </Autocomplete>
        </div>
      ) : null}
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          onLoad={onMapLoad}
          onZoomChanged={onMapZoomChanged}
          zoom={zoomLevel}
          options={{ disableDoubleClickZoom: true }}
        >
          {marker && <Marker position={marker} />}
          {result && result.latitude && result.longitude && (
            <Marker
              position={{ lat: result.latitude, lng: result.longitude }}
              icon={{ url: "/rise/iec.png" }}
            />
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
      {result ? (
        <List
          itemLayout="horizontal"
          dataSource={[
            {
              title: "Polling Division",
              value:
                result.pollingDivision.pdName || result.pollingDivision.pdCode,
            },
            {
              title: "Province",
              value: result.pollingDivision.province.name,
            },
            {
              title: "Municipality",
              value: result.pollingDivision.constituency?.name ?? "-",
            },
            result.pollingDivision.electoralDivision && {
              title: "Ward",
              value: result.pollingDivision.electoralDivision.name,
            },
          ].filter((p) => !!p.value)}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
      ) : null}
    </div>
  );
}

const CHECK_POLLING_STATION = gql`
  mutation CheckPollingStation($latitude: Float!, $longitude: Float!) {
    checkPollingStation(latitude: $latitude, longitude: $longitude) {
      pollingDivision {
        id
        province {
          name
        }
        constituency: municipality {
          name
        }
        electoralDivision: ward {
          name
        }
        pdCode: code
        pdName: name
      }
      latitude
      longitude
      response
    }
  }
`;
