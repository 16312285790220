import React, { useState, useEffect } from "react";
import {
  message,
  Button,
  Select,
  Spin,
  Form,
  Input,
  Card,
  Row,
  Col,
  Divider,
  Skeleton,
  Space,
} from "antd";
import { Flex, Box, Text } from "rebass";
import FormContainer from "../Form/FormContainer";
import { validateForm, validatePhoneNumber } from "../../common/utils";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import { Checkbox, Tabs } from "antd";
import {
  LoadElectoralDivisions,
  LoadConstituencies,
  LoadRegions,
  LoadProvinces,
  LoadMunicipalities,
  LoadWards,
} from "../../components/Structures/StrutureLoaders";
import config from "../../common/config";
import GotvStructures from "../Gotv/GotvStructures";
import Title from "antd/es/typography/Title";
import { PollList } from "../../components/Structures/PollList";

const { TabPane } = Tabs;

export const roles = [
  {
    value: "CANVASSER",
    id: "CANVASSER",
    label: "Canvasser",
    level: 1,
    requires: ["exclusions", "role", "pd"],
  },
  {
    value: "PD",
    id: "PD",
    label: "Polling Division",
    level: 2,
    requires: ["exclusions", "role", "pd"],
  },
  {
    value: "WARD",
    id: "WARD",
    label: "Ward",
    level: 3,
    requires: ["exclusions", "role", "electoralDivision"],
  },
  {
    value: "MUNICIPALITY",
    id: "MUNICIPALITY",
    label: "Municipality",
    level: 4,
    requires: ["exclusions", "role", "municipality"],
  },
  {
    value: "PROVINCE",
    id: "PROVINCE",
    label: "Province",
    level: 5,
    requires: ["exclusions", "role", "province"],
  },
  {
    value: "NATIONAL",
    id: "NATIONAL",
    label: "National Campaign",
    level: 7,
    requires: ["exclusions", "role", "national"],
  },
];

const forms = {
  addUser: (viewer) => ({
    title: "Add User",
    fields: [
      {
        label: config[process.env.REACT_APP_SITE_ID].voterId,
        fieldId: "registrationNumber",
        required: true,
      },
      {
        label: "Cellphone Number",
        fieldId: "contact",
        required: true,
      },
      {
        label: "Email",
        fieldId: "email",
        required: true,
        type: "email",
      },
    ],
  }),
  editUser: {
    title: "Find User",
    fields: [
      {
        label: config[process.env.REACT_APP_SITE_ID].voterId,
        fieldId: "registrationNumber",
        required: true,
        disabled: true,
      },
      {
        label: "Cellphone Number",
        fieldId: "contact",
        required: true,
      },
      {
        label: "Email",
        fieldId: "email",
        required: true,
        type: "email",
      },
    ],
  },
};

const CountryComponent = ({ value, onSelect, code }) => {
  return (
    <Form.Item label="Country">
      <Select
        style={{ width: "100%" }}
        onChange={() =>
          onSelect({
            national: config[process.env.REACT_APP_SITE_ID].country.code,
          })
        }
        value={
          value.national || config[process.env.REACT_APP_SITE_ID].country.code
        }
      >
        <Select.Option
          value={config[process.env.REACT_APP_SITE_ID].country.code}
        >
          {config[process.env.REACT_APP_SITE_ID].country.name}
        </Select.Option>
      </Select>
    </Form.Item>
  );
};

export const ConstituencyComponent = ({
  value = {},
  requires,
  onSelect,
  structures = [],
  code = "code",
}) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Constituency" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a constituency"
          optionFilterProp="name"
          value={value.constituency}
          onChange={(value) =>
            onSelect({ constituency: parseInt(value), pd: null, ps: null })
          }
        >
          <Select.Option value={null}>All</Select.Option>
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c[code]} value={c[code]}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item label="Constituency">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadConstituencies
      {...value}
      render={(loading, constituencies) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );

        return (
          <Form.Item label="Constituency" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a constituency"
              optionFilterProp="name"
              value={value.constituency}
              onChange={(value) =>
                onSelect({ constituency: value, pd: null, ps: null })
              }
            >
              <Select.Option value={null}>All</Select.Option>
              {constituencies
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c[code]} value={c[code]}>
                    {c.code}: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

const ProvinceComponent = ({
  value = {},
  requires,
  onSelect,
  structures = [],
  code = "code",
}) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Province" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a province"
          optionFilterProp="name"
          value={value.province}
          onChange={(value) =>
            onSelect({ province: value, pd: null, ps: null })
          }
        >
          <Select.Option value={null}>All</Select.Option>
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c[code]} value={c[code]}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item label="Province">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadProvinces
      {...value}
      render={(loading, provinces) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );
        return (
          <Form.Item label="Province" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a province"
              optionFilterProp="name"
              value={value.province}
              onChange={(value) =>
                onSelect({ province: value, pd: null, ps: null })
              }
            >
              <Select.Option value={null}>All</Select.Option>
              {provinces
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c[code]} value={c[code]}>
                    {c.code}: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

const MunicipalityComponent = ({
  value = {},
  requires,
  onSelect,
  structures = [],
  code = "code",
}) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Municipality" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a municipality"
          optionFilterProp="name"
          value={value.municipality}
          onChange={(value) =>
            onSelect({ municipality: value, pd: null, ps: null })
          }
        >
          <Select.Option value={null}>All</Select.Option>
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c[code]} value={c[code]}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item label="Municipality">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadMunicipalities
      {...value}
      render={(loading, municipalities) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );
        return (
          <Form.Item label="Municipality" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a municipality"
              optionFilterProp="name"
              value={value.municipality}
              onChange={(value) =>
                onSelect({ municipality: value, pd: null, ps: null })
              }
            >
              <Select.Option value={null}>All</Select.Option>
              {municipalities
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c[code]} value={c[code]}>
                    {c.code}: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

const WardComponent = ({ value = {}, requires, onSelect, structures = [] }) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Wards" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a ward"
          optionFilterProp="name"
          value={value.ward}
          onChange={(value) => onSelect({ ward: value.toString() })}
        >
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c.code} value={c.code}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item className="w-25" label="Wards">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadWards
      {...value}
      render={(loading, wards) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );
        return (
          <Form.Item label="Wards" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a ward"
              optionFilterProp="name"
              value={value.ward}
              onChange={(value) => onSelect({ ward: value.toString() })}
            >
              <Select.Option value={null}>All</Select.Option>
              {wards
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c.code} value={c.code}>
                    WARD: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

export const ElectoralComponent = ({
  value = {},
  requires,
  onSelect,
  structures = [],
}) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Electoral Division" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a electoral division"
          optionFilterProp="name"
          value={value.electoralDivision}
          onChange={(value) => onSelect({ electoralDivision: value })}
        >
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c.code} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item className="w-25" label="Electoral Division">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadElectoralDivisions
      {...value}
      render={(loading, electoralDivisions) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );
        return (
          <Form.Item label="Electoral Divisions" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a electoral division"
              optionFilterProp="name"
              value={value.electoralDivision}
              onChange={(value) => onSelect({ electoralDivision: value })}
            >
              <Select.Option value={null}>All</Select.Option>
              {electoralDivisions
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c.code} value={c.id}>
                    {c.code}: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

export const RegionComponent = ({
  value = {},
  onSelect,
  requires,
  structures,
  code = "code",
}) => {
  if (structures && structures.length) {
    return (
      <Form.Item label="Regions" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a region"
          optionFilterProp="name"
          value={value.region}
          onChange={(value) => onSelect({ region: value })}
        >
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c[code]} value={c[code]}>
                {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item className="w-25" label="Region">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <LoadRegions
      {...value}
      render={(loading, regions) => {
        if (loading)
          return (
            <Space>
              <Skeleton.Input />
            </Space>
          );
        return (
          <Form.Item label="Regions" style={{ fontWeight: 700 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a region"
              optionFilterProp="name"
              value={value.region}
              onChange={(value) => onSelect({ region: value })}
            >
              <Select.Option value={null}>All</Select.Option>
              {regions
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c[code]} value={c[code]}>
                    {c.code}: {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

export const PSComponent = ({ value = {}, requires = [], onSelect, code }) => {
  if (!requires.some((r) => value[r]))
    return (
      <Form.Item className="w-25" label="Polling Stations">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <PSList
      pd={value.pd}
      value={value.ps}
      code={code}
      onChange={(value) => onSelect({ ps: value })}
    />
  );
};

const PSList = ({ pd, code = "id", ...restProps }) => {
  const { data, loading } = useQuery(GET_POLLING_STATIONS, {
    variables: {
      input: {
        pd,
      },
    },
  });

  if (loading || !data)
    return (
      <Space>
        <Skeleton.Input />
      </Space>
    );

  const { polls } = data;
  const [poll] = polls;

  return (
    <Form.Item label={"Polling Stations"} style={{ minWidth: "250px" }}>
      <Select style={{ width: "100%" }} {...restProps}>
        <Select.Option value={null}>All</Select.Option>
        {poll.streams.map((stream) => (
          <Select.Option key={stream[code]} value={stream[code]}>
            PS: {stream.name} ({stream.surnames[0]}-
            {stream.surnames[stream.surnames.length - 1]})
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const GET_POLLING_STATIONS = gql`
  query getPollingStations($input: PollingDivisionInput) {
    polls(input: $input) {
      pdName
      streams {
        id
        name
        surnames
      }
    }
  }
`;

const PDComponent = ({
  value = {},
  requires = [],
  onSelect,
  structures,
  code = "id",
}) => {
  if (structures.length) {
    return (
      <Form.Item label="Polling Division" style={{ fontWeight: 700 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a polling division"
          optionFilterProp="name"
          value={value.pd}
          onChange={(value) => onSelect({ pd: value, ps: null })}
        >
          <Select.Option value={null}>All</Select.Option>
          {structures
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((c) => (
              <Select.Option key={c[code]} value={c[code]}>
                {c.type} {c.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (!requires.some((r) => value[r]))
    return (
      <Form.Item className="w-25" label="Polling Division">
        <Select
          style={{ width: "100%" }}
          disabled
          placeholder="Select parent structure to continue"
        />
      </Form.Item>
    );

  return (
    <PollList
      showTitle={true}
      code={code}
      onChange={(value) => {
        console.log(code, value);
        onSelect({ pd: value, ps: null });
      }}
      {...value}
    />
  );
};

export const StructuresComponent = ({
  onSelect,
  structures,
  value,
  item,
  onRemove,
  children,
  defaultRole = null,
  formRoles = roles,
  vertical = false,
  components = {},
  exclude = [],
  showDefault = true,
}) => {
  let Components = {
    CountryComponent: {
      structure: "national",
      level: 7,
      base: CountryComponent,
      userStructures: structures.national,
      requires: ["national"],
      defaultValue:
        structures.national && structures.national.length
          ? structures.national[0].code
          : null,
      extras: components.national || {},
    },
    ProvinceComponent: {
      structure: "province",
      level: 5,
      base: ProvinceComponent,
      userStructures: structures.provinces,
      requires: ["national", "province"],
      defaultValue:
        structures.provinces && structures.provinces.length
          ? structures.provinces[0].code
          : null,
      extras: components.province || {},
    },
    MunicipalityComponent: {
      structure: "municipality",
      level: 4,
      base: MunicipalityComponent,
      userStructures: structures.municipalities,
      requires: ["national", "province", "municipality"],
      defaultValue:
        structures.municipalities && structures.municipalities.length
          ? structures.municipalities[0].code
          : null,
      extras: components.municipality || {},
    },
    WardComponent: {
      structure: "ward",
      level: 3,
      base: WardComponent,
      userStructures: structures.wards,
      requires: ["national", "province", "municipality"],
      defaultValue:
        structures.wards && structures.wards.length
          ? structures.wards[0].code
          : null,
      extras: components.ward || {},
    },

    PDComponent: {
      structure: "pd",
      level: 2,
      base: PDComponent,
      components: [],
      userStructures: structures.pollingDivisions,
      requires: ["pd", "province", "municipality"],
      defaultValue: null,
      extras: components.pd || {},
    },
  };

  const UserComponent = Object.keys(Components).reduce((result, item) => {
    if (
      Components[item].userStructures &&
      Components[item].userStructures.length &&
      !result
    ) {
      result = Components[item];
    }

    return result;
  }, null);

  const userRoles = formRoles.filter(
    (role) => role.level <= UserComponent.level
  ); //UserComponent.level
  const selectedRole = defaultRole
    ? formRoles.find((r) => r.id === defaultRole)
    : value.role
    ? formRoles.find((r) => r.id === value.role)
    : null;

  useEffect(
    () => {
      if (selectedRole && showDefault) {
        onSelect({
          [UserComponent.structure]: UserComponent.defaultValue,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRole]
  );

  const StructureComponents = Object.keys(Components).reduce((result, item) => {
    if (
      selectedRole &&
      Components[item].level >= selectedRole.level &&
      UserComponent.level >= Components[item].level &&
      !exclude.includes(item)
    ) {
      result.push(item);
    }

    return result;
  }, []);

  return (
    <Box
      className={`UsersScreen_StructuresComponent_${vertical ? "Box" : "Flex"}`}
    >
      {!defaultRole ? (
        <Box mr={[2]}>
          <FormContainer
            values={value}
            onSetValues={onSelect}
            form={{
              fields: [
                {
                  label: "Role",
                  fieldId: "role",
                  type: "select",
                  options: userRoles,
                  required: true,
                },
              ],
            }}
          />
        </Box>
      ) : null}

      {StructureComponents.map((c) => {
        const {
          base: Component,
          userStructures: structures,
          requires,
          extras,
        } = Components[c];

        return (
          <Component
            onSelect={onSelect}
            value={value}
            structures={structures}
            requires={requires}
            {...extras}
          />
        );
      })}

      {!defaultRole &&
      selectedRole &&
      selectedRole.requires.includes("exclusions") ? (
        <Form.Item label="Permissions Excluded" name="layout">
          <Checkbox.Group
            options={[
              { label: "Reporting", value: "Reporting" },
              { label: "Admin", value: "Admin" },
              { label: "Canvassing", value: "Canvassing" },
              { label: "Downloads", value: "Downloads" },
            ]}
            onChange={(values) => onSelect({ exclusions: values })}
          />
        </Form.Item>
      ) : null}
      {onRemove && (
        <Button type={"link"} color="red" onClick={() => onRemove(item)}>
          Remove
        </Button>
      )}
      {children}
    </Box>
  );
};

const AddUser = ({ viewer }) => {
  const [values, setValues] = useState({
    contact: "",
  });
  const [loader, setloading] = useState(false);
  const [structures, setStructures] = useState([]);
  const [errors, setErrors] = useState({});
  const [addUser] = useMutation(ADD_USER);

  const form = forms.addUser();

  const onAddUser = () => {
    setErrors({});

    if (!structures.length) {
      setErrors({
        structures: "At least one valid structure required",
      });
      return;
    }
    let hasParams = true;
    structures.forEach((s) => {
      if (!s.role) {
        hasParams = false;
      } else {
        const { requires } = roles.find((r) => r.value === s.role);
        requires.forEach((r) => {
          if (!s[r]) {
            hasParams = false;
          }
        });
      }
    });

    if (!hasParams) {
      setErrors({
        structures: "Please select all required structure fields",
      });
      return;
    }

    const formErrors = validateForm(form.fields, values);

    if (Object.keys(errors).length) {
      setErrors({
        ...formErrors,
      });
      return;
    }
    setloading(true);

    const input = {
      ...values,
      contact: validatePhoneNumber(values.contact),
      structures: structures.map((s) => {
        const { requires } = roles.find((r) => r.value === s.role);

        return requires.reduce((result, item) => {
          result[item] = s[item];

          return result;
        }, {});
      }),
    };

    addUser({
      variables: {
        input: { ...input },
      },
    })
      .then(() => {
        setloading(false);
        setValues({});
        setStructures([]);
        message.success("User Added");
      })
      .catch((err) => {
        setloading(false);
        alert(
          "Error creating user. Check if the user already exists or try again."
        );
      });
  };

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onStructureChange = (payload, index) => {
    setStructures(
      structures.map((item, action) => {
        if (action === index) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      })
    );
  };

  if (loader) return <Spin />;

  return (
    <Form layout="vertical">
      <Title level={4}>Add User</Title>
      <Divider />

      <FormContainer
        onSetValues={onSetValues}
        values={values}
        errors={errors}
        form={form}
      />
      {structures.map((structure, index) => {
        return (
          <StructuresComponent
            onSelect={(payload) => onStructureChange(payload, index)}
            onSetValues={onSetValues}
            values={values}
            value={structure}
            item={index}
            structures={viewer.structures}
            onRemove={() =>
              setStructures(structures.filter((_, action) => index !== action))
            }
          />
        );
      })}
      {errors && errors.structures ? (
        <Box bg="red" p={[2]}>
          <Text color="white">{errors.structures}</Text>
        </Box>
      ) : null}
      <Box px={[2]} mt={[2]}>
        <Button
          type="primary"
          onClick={onAddUser}
          disabled={structures.length === 0}
        >
          Save
        </Button>
        <Button
          type="link"
          onClick={() => setStructures([...structures, { exclusions: [] }])}
        >
          Add Role
        </Button>
      </Box>
    </Form>
  );
};

const EditUser = ({ viewer }) => {
  const [values, setValues] = useState({
    contact: "",
    roles: [],
  });
  const [loader, setloading] = useState(false);
  const [structures, setStructures] = useState([]);
  const [errors, setErrors] = useState({});
  const [updateUser] = useMutation(UPDATE_USER);
  const [removeUserStructure] = useMutation(REMOVE_USER_STRUCTURE);
  const [addUserStructure] = useMutation(ADD_USER_STRUCTURE);

  const [input, setInput] = useState("");

  const [fetchUser, { data, loading, refetch }] = useLazyQuery(getUser, {
    onCompleted: (value) => {
      const { user } = value;
      if (!user) {
        message.warn("User does not exist");
      }
    },
  });

  useEffect(() => {
    if (data) {
      setValues({
        contact: "",
        roles: [],
        ...data.user,
      });
    }
  }, [data]);

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onRemove = (id) => {
    removeUserStructure({
      variables: {
        id,
      },
    }).then(() => {
      refetch();
      message.success("User Updated");
    });
  };

  const onUpdateUser = () => {
    setErrors({});

    const formErrors = validateForm(forms.editUser.fields, values);

    if (Object.keys(errors).length) {
      setErrors({
        ...formErrors,
      });
      return;
    }

    const { id, roles, __typename, ...formValues } = values;

    setloading(true);
    updateUser({
      variables: {
        input: {
          ...formValues,
        },
      },
    })
      .then(() => {
        setStructures([]);
        fetchUser({
          variables: {
            registrationNumber: input,
          },
        });
        setloading(false);
        message.success("User Updated");
      })
      .catch((err) => {
        setloading(false);
        alert(err);
      });
  };

  const onAddUserStructure = () => {
    setErrors({});

    if (!structures.length) {
      setErrors({
        structures: "At least one valid structure required",
      });
      return;
    }
    let hasParams = true;
    structures.forEach((s) => {
      if (!s.role) {
        hasParams = false;
      } else {
        const { requires } = roles.find((r) => r.value === s.role);
        requires.forEach((r) => {
          if (!s[r]) {
            hasParams = false;
          }
        });
      }
    });

    if (!hasParams) {
      setErrors({
        structures: "Please select all required structure fields",
      });
      return;
    }

    const { registrationNumber } = values;

    setloading(true);

    addUserStructure({
      variables: {
        input: {
          registrationNumber,
          structures: structures.map((s) => {
            const { requires } = roles.find((r) => r.value === s.role);

            return requires.reduce((result, item) => {
              if (item === "region") {
                result[item] = s[item].toString();
              } else if (s[item]) {
                result[item] = s[item];
              }

              return result;
            }, {});
          }),
        },
      },
    })
      .then(() => {
        setStructures([]);
        refetch();
        setloading(false);
        message.success("User Structure Added");
      })
      .catch((err) => {
        setloading(false);
        alert(err);
      });
  };

  const onStructureChange = (payload, index) => {
    setStructures(
      structures.map((item, action) => {
        if (action === index) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Title level={4}>Search User</Title>
      <Divider />
      <Flex my={[2]}>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter ID number..."
        />
        <Box px={[2]}>
          <Button
            disable={loader}
            onClick={() =>
              fetchUser({
                variables: {
                  registrationNumber: input,
                },
              })
            }
          >
            Search
          </Button>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center">
          <Spin />
        </Flex>
      ) : null}
      {data && data.user ? (
        <>
          <Card title="User Profile" style={{ marginBottom: "10px" }}>
            <FormContainer
              onSetValues={onSetValues}
              values={values}
              errors={errors}
              form={forms.editUser}
            />
            <Box mb={[2]}>
              <Button type="primary" onClick={() => onUpdateUser()}>
                Update Profile
              </Button>
            </Box>
          </Card>
          <Card title="User Roles" style={{ marginBottom: "10px" }}>
            {values.roles.map((role) => {
              return (
                <Box mb={[3]}>
                  <Flex
                    mb={[2]}
                    pb={[2]}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderBottom: "1px solid #eee" }}
                  >
                    <Title level={5} fontSize={[2]}>
                      {role.role}
                    </Title>
                    <Button
                      type={"link"}
                      color="red"
                      onClick={() => onRemove(role.id)}
                    >
                      Remove
                    </Button>
                  </Flex>
                  {role.structures &&
                    role.structures.map((structure, index) => {
                      if (structure.type === "PollingDivision") {
                        return (
                          <GotvStructures
                            structure={{ pd: parseInt(structure.code) }}
                          />
                        );
                      }
                      return (
                        <Flex justifyContent="space-between">
                          <div>{structure.type}</div>
                          <div>{structure.code}</div>
                        </Flex>
                      );
                    })}
                </Box>
              );
            })}
          </Card>
          <Card title="Add User Role" style={{ marginBottom: "10px" }}>
            {structures.map((structure, index) => {
              return (
                <StructuresComponent
                  onSelect={(payload) => onStructureChange(payload, index)}
                  value={structure}
                  item={index}
                  structures={viewer.structures}
                  onRemove={() =>
                    setStructures(
                      structures.filter((_, action) => index !== action)
                    )
                  }
                />
              );
            })}

            <Box px={[2]} mt={[2]}>
              <Button
                type="link"
                onClick={() =>
                  setStructures([...structures, { exclusions: [] }])
                }
              >
                Add Structure
              </Button>
              <Button onClick={onAddUserStructure}>
                Save Structure Permissions
              </Button>
            </Box>
          </Card>
        </>
      ) : null}
    </>
  );
};

const UsersScreen = () => {
  const { data: user, loading: viewerLoader } = useQuery(GET_SEARCH_OPTIONS);

  if (viewerLoader) return <Spin />;
  return (
    <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
      <TabPane tab="Search user" key="1">
        <Row type="flex" justify="center" style={{ minHeight: "100vh" }}>
          <Col style={{ width: "100%" }}>
            <EditUser viewer={user.viewer} />
          </Col>
        </Row>
      </TabPane>
      <TabPane tab="Add user" key="2">
        <Row type="flex" justify="center" style={{ minHeight: "100vh" }}>
          <Col style={{ width: "100%" }}>
            <AddUser viewer={user.viewer} />
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default UsersScreen;

const ADD_USER = gql`
  mutation addUser($input: AddUserInput) {
    addUser(input: $input) {
      registrationNumber
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: AddUserInput) {
    updateUser(input: $input) {
      id
      registrationNumber
      email
      contact: cellphone
      roles {
        id
        role
        exclusions
        structures {
          type
          code
        }
      }
    }
  }
`;

export const REMOVE_USER_STRUCTURE = gql`
  mutation removeUserStructure($id: ID!) {
    removeUserStructure(id: $id)
  }
`;

export const ADD_USER_STRUCTURE = gql`
  mutation addUserStructure($input: AddUserInput) {
    addUserStructure(input: $input)
  }
`;

export const UPDATE_VOTER = gql`
  mutation updateVoter($voterId: String!, $input: UpdateVoterInput!) {
    updateVoter(voterId: $voterId, input: $input) {
      id
    }
  }
`;

const getUser = gql`
  query searchOptions($registrationNumber: String!) {
    user(registrationNumber: $registrationNumber) {
      id
      registrationNumber
      email
      contact: cellphone
      roles {
        id
        role
        exclusions
        structures {
          type
          code
        }
      }
    }
  }
`;

export const GET_SEARCH_OPTIONS = gql`
  query searchOptions($role: String) {
    viewer {
      structures {
        national {
          type
          code
          name
        }
        electoralDivisions {
          id
          code
          name
        }
        pollingDivisions {
          id
          type
          code
          name
          pdName: name
        }
        regions {
          type
          code
          name
        }
        constituencies {
          type
          code
          name
        }
        municipalities {
          type
          code
          name
        }
        wards {
          type
          code
          name
        }
      }
      electoralDivisions(role: $role) {
        id
        code
        name
        constituency {
          id
          code
        }
      }
      constituencies(role: $role) {
        type
        code
        name
      }
      municipalities(role: $role) {
        type
        code
        name
      }
      wards(role: $role) {
        type
        code
        name
      }
      regions(role: $role) {
        type
        code
        name
      }
    }
  }
`;
