import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Tabs, Button, DatePicker } from "antd";
import dayjs from "dayjs";
import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Box } from "rebass";
import TagMap from "./ui/TagMap";
import moment from "moment";
import DownloadsContainer from "./DownloadsContainer";
import { PrivateComponent, PrivateRoleRoute } from "../../App";
import Affiliation from "./Affiliation";
import Issues from "./Issues";
import DailyCanvass from "./DailyCanvass";
import Canvasser from "./Canvasser";
import AdminScreen from "../Admin/AdminScreen";

const { Option } = Select;

export const STRUCTURE_MAP = {
  pd: "pd",
  ward: "ward",
  municipality: "municipality",
  province: "province",
  national: "country",
};

const GROUP_BY_MAP = {
  pd: "pd",
  ward: "ward",
  municipality: "municipality",
  province: "province",
  national: "country",
};

const COMPONENT_TYPES = {
  TAG_MAP: "TAG_MAP",
  VOTER_TABLE: "VOTER_TABLE",
  AFFILIATION: "AFFILIATION",
  ISSUES: "ISSUES",
  DAILY_CANVASS: "DAILY_CANVASS",
  CANVASSER: "CANVASSER",
};

const COMPONENT_MAP = {
  [COMPONENT_TYPES.TAG_MAP]: TagMap,
  [COMPONENT_TYPES.AFFILIATION]: Affiliation,
  [COMPONENT_TYPES.ISSUES]: Issues,
  [COMPONENT_TYPES.DAILY_CANVASS]: DailyCanvass,
  [COMPONENT_TYPES.CANVASSER]: Canvasser,
};

const dateFormat = "YYYY/MM/DD";

const ReportingStructure = (props) => {
  const { data: user, loading: viewerLoader } = useQuery(GET_SEARCH_OPTIONS);

  let code = props.code;

  if (
    ["electoralDivision", "constituency", "region"].includes(
      STRUCTURE_MAP[props.structure]
    )
  ) {
    code = parseInt(code, 10);
  }
  const [structure, setStructure] = useState({
    [STRUCTURE_MAP[props.structure]]: code,
    startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: moment().add(1, "month").format("YYYY-MM-DD"),
    groupby: GROUP_BY_MAP[props.structure],
    tags: [],
    affiliation: "",
  });

  useEffect(() => {
    setStructure((structure) => {
      let c = props.code;

      return {
        ...structure,
        [STRUCTURE_MAP[props.structure]]: c,
        groupby: GROUP_BY_MAP[props.structure],
      };
    });
  }, [props.structure, props.code]);

  const onSetStructure = (payload) => {
    const extra = {};

    if (payload.national) {
      extra.groupby = "country";
    }

    if (payload.province) {
      extra.groupby = "province";
    }

    if (payload.municipality) {
      extra.groupby = "municipality";
    }

    if (payload.ward) {
      extra.groupby = "ward";
    }

    if (payload.pd) {
      extra.groupby = "pd";
    }

    setStructure({
      ...structure,
      ...payload,
      ...extra,
    });
  };

  const { loading, error, data } = useQuery(GET_TAGS);

  const isLoading = (loading || viewerLoader) && (!data || !user);

  if (error) return `Error! ${error.message}`;

  const { tags = [] } = data ?? {};

  const reports = {
    issue: {
      groupby: true,
      date: true,
      title: "Issues",
      component: COMPONENT_TYPES.ISSUES,
      tags: true,
    },
    affiliation: {
      title: "Affiliation",
      component: COMPONENT_TYPES.AFFILIATION,
      groupby: true,
      date: true,
      tags: true,
    },
    counts: {
      title: "Daily Canvass Graphs",
      component: COMPONENT_TYPES.DAILY_CANVASS,
      groupby: true,
      date: true,
    },
    canvasser: {
      date: true,
      title: "Canvasser",
      component: COMPONENT_TYPES.CANVASSER,
    },
    tagByLocation: {
      tags: true,
      date: true,
      title: "Tag Locations",
      component: COMPONENT_TYPES.TAG_MAP,
    },
  };

  return (
    <AdminScreen title="Reporting" isLoading={isLoading}>
      <Form layout="vertical">
        <StructuresComponent
          onSelect={onSetStructure}
          value={structure}
          defaultRole={"PD"}
          structures={user?.viewer.structures ?? []}
        >
          <Box flexBasis="100%" />
          <Button
            onClick={() =>
              onSetStructure({
                constituency: null,
                electoralDivision: null,
                pd: null,
                region: null,
                national: null,
                [STRUCTURE_MAP[props.structure]]: props.code,
                groupby: GROUP_BY_MAP[props.structure],
              })
            }
          >
            Reset
          </Button>
        </StructuresComponent>

        <Tabs defaultActiveKey="0">
          {Object.keys(reports).map((r, index) => {
            const report = reports[r];
            const Component = COMPONENT_MAP[report.component];

            if (!Component) return null;

            return (
              <Tabs.TabPane tab={report.title} key={index}>
                <Row gutter={[16, 8]}>
                  {report.date ? (
                    <Col key={`date-${index}`}>
                      <Form.Item label="Date">
                        <DatePicker.RangePicker
                          defaultValue={[
                            dayjs(structure.startDate, dateFormat),
                            dayjs(structure.endDate, dateFormat),
                          ]}
                          format={dateFormat}
                          onChange={(_, dates) => {
                            onSetStructure({
                              startDate: dates[0],
                              endDate: dates[1],
                            });
                          }}
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}

                  {report.tags && (
                    <Col xs={24} sm={24} md={8} lg={8} key={`date-${index}`}>
                      <Form.Item label="Tags">
                        <Select
                          mode="multiple"
                          placeholder="Select tags"
                          onChange={(value) => {
                            onSetStructure({
                              tags: value,
                            });
                          }}
                          optionLabelProp="label"
                          style={{ width: "100%" }}
                        >
                          {tags.map((age) => (
                            <Option value={age.id} label={age.name}>
                              {age.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {report.groupby ? (
                    <Col xs={24} sm={24} md={8} lg={8} key={`date-${index}`}>
                      <Form.Item label="Group By" className="w-100">
                        <Select
                          value={structure.groupby}
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onSetStructure({ groupby: value })
                          }
                        >
                          {structure.province ||
                          structure.municipality ||
                          structure.ward ||
                          structure.pd ? null : (
                            <Select.Option value="country">
                              Country
                            </Select.Option>
                          )}
                          {structure.municipality ||
                          structure.ward ||
                          structure.pd ? null : (
                            <Select.Option value="province">
                              Province
                            </Select.Option>
                          )}
                          {structure.ward || structure.pd ? null : (
                            <Select.Option value="municipality">
                              Municipality
                            </Select.Option>
                          )}
                          {structure.pd ? null : (
                            <Select.Option value="ward">Ward</Select.Option>
                          )}
                          <Select.Option value="pd">
                            Polling District
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>

                <Component
                  {...report}
                  tags={tags}
                  key={new Date()}
                  query={structure}
                />
              </Tabs.TabPane>
            );
          })}

          <Tabs.TabPane tab={"Downloads"} key={Object.keys(reports).length + 1}>
            <PrivateComponent roles={["NATIONAL", "REGIONAL", "CONSTITUENCY"]}>
              <PrivateRoleRoute role={"Downloads"}>
                <DownloadsContainer structure={structure} tags={tags} />
              </PrivateRoleRoute>
            </PrivateComponent>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </AdminScreen>
  );
};

const GET_TAGS = gql`
  {
    tags {
      id
      color
      name
      code
      category
    }
  }
`;

export default ReportingStructure;
