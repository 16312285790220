const config = {
  demo: {
    latitude: 18.1155174,
    longitude: -77.2760026,
    color: "#2596be",
    voterId: "Voter Number",
    copyright: "",
    logo: require("./demo-logo.png"),
    country: {
      iso: "JM",
      code: "JM",
      name: "Jamaica",
    },
    template: "calendar",
    excel: false,
    gotvLevel: "pd",
    capture: null,
    canvass: { form: "canvassForm", label: "Start Canvass" },
    reportTemplate: "canvass-template",
  },
  ulp: {
    latitude: 12.9843,
    longitude: -61.2872,
    color: "#1e349b",
    voterId: "Voter Number",
    logo: require("./ulp-logo.png"),
    copyright: "",
    country: {
      iso: "VC",
      code: "SVG",
      name: "Saint Vincent and the Grenadines",
    },
    template: "block",
    excel: true,
    gotvLevel: "constituency",
    capture: null,
    canvass: { form: "canvassForm", label: "Start Canvass" },
    reportTemplate: "canvass-template",
  },
  pnp: {
    latitude: 18.1155174,
    longitude: -77.2760026,
    color: "#ff7000",
    voterId: "Registration Number",
    copyright: "Property of The People's National Party, Jamaica",
    logo: require("./pnp-logo.png"),
    country: {
      iso: "JM",
      code: "JM",
      name: "Jamaica",
    },
    template: "calendar",
    excel: false,
    gotvLevel: "pd",
    capture: null,
    canvass: { form: "canvassForm", label: "Start Canvass" },
    reportTemplate: "canvass-template",
  },
  fnm: {
    latitude: 25.0343,
    longitude: -77.3963,
    color: "#0c549e",
    voterId: "Voter Number",
    copyright: `Canvass.Today`,
    logo: require("./fnm-logo.png"),
    country: {
      iso: "BS",
      code: "BS",
      name: "Bahamas",
    },
    template: "block",
    excel: true,
    gotvSheet: true,
    gotvLevel: "pd",
    capture: { form: "captureForm", label: "Data Capture" },
    canvass: { form: "canvassForm", label: "Door to Door Canvass" },
    previousResult: "2017",
    parties: ["FNM", "PLP", "DNA", "OTHER", "TOTAL"],
    reportTemplate: "canvass-template",
  },
  actionSA: {
    latitude: 25.0343,
    longitude: -77.3963,
    color: "#05B615",
    voterId: "ID Number",
    copyright: `Canvass.Today`,
    logo: require("./act_logo.png"),
    country: {
      iso: "ZA",
      code: "ZA",
      name: "South Africa",
    },
    template: "block",
    excel: true,
    gotvLevel: "pd",
    capture: null,
    canvass: { form: "canvassForm", label: "Canvass", overflow: true },
    previousResult: "",
    parties: [],
    reportTemplate: "canvass-template",
  },
  upp: {
    latitude: 17.0747,
    longitude: -61.8175,
    color: "#24296B",
    voterId: "UPP/Voter ID",
    copyright: `Canvass.Today`,
    logo: require("./upp-logo.png"),
    country: {
      iso: "AG",
      code: "AG",
      name: "Antigua and Barbuda",
    },
    template: "block",
    excel: true,
    gotvLevel: "pd",
    capture: { form: "captureForm", label: "Data Capture" },
    canvass: { form: "canvassForm", label: "Canvass", overflow: false },
    previousResult: "",
    parties: [],
    reportTemplate: "canvass",
  },
  risem: {
    latitude: -33.918861,
    longitude: 18.4233,
    color: "#0e121e",
    voterId: "ID Number",
    copyright: `Canvass.Today`,
    logo: require("./rise_logo.png"),
    country: {
      iso: "ZA",
      code: "ZA",
      name: "South Africa",
    },
    template: "block",
    excel: true,
    gotvLevel: "pd",
    capture: null,
    canvass: { form: "canvassForm", label: "Canvass", overflow: false },
    previousResult: "",
    parties: [],
    reportTemplate: "canvass-template",
  },
};

module.exports = config;
