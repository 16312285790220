import React, { useContext } from "react";
import { navigate } from "@reach/router";

import { ResultsContext } from "./ResultsProvider";
import { Box } from "rebass";

import ResultsContainer from "./ResultsContainer";
import AdminScreen from "../Admin/AdminScreen";

const ResultScreen = (props) => {
  const { search } = useContext(ResultsContext);
  return (
    <AdminScreen title="Search Results">
      <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
        <ResultsContainer
          search={search}
          useWindow={true}
          icon="right"
          onItemClick={(item, path = "") =>
            navigate(`/voter/${item.registrationNumber}/${path}`)
          }
        />
      </Box>
    </AdminScreen>
  );
};

export default ResultScreen;
