import React, { useState } from "react";

import { Spin, Form, Input, Button, Divider, Card, Typography } from "antd";

import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";
import Title from "antd/es/typography/Title";
import { parsePhoneNumber } from "libphonenumber-js";
import AutoSearchComponent from "../../components/Autocomplete";
import useCheckVoterRegistration from "../../components/CreateVoter";
import { formatAddress, validateForm } from "../../common/utils";

const LookupScreen = () => {
  return (
    <AdminScreen title={"Voter Lookup"}>
      <Card title="Lookup Voter">
        <VoterRegister
          title={"Enter Voter Details"}
          description={
            "Fill the the form to lookup the voter on the IEC database and create a new voter record"
          }
        />
      </Card>
    </AdminScreen>
  );
};

export const VoterRegister = ({ title, description }) => {
  const { checkVoterRegistration } = useCheckVoterRegistration();

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    idnumber: "",
    contact: "",
    address: null,
  });

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const onChange = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onSearch = async () => {
    setLoading(true);
    // validate the form here before going to results
    const { contact, address = {}, ...args } = values;

    const mappedAddress = address ? formatAddress(address) : null;

    const input = {
      ...args,
      address: address ? mappedAddress : null,
      contact: contact ? parsePhoneNumber(contact, "ZA").number : "",
    };

    const validationErrors = validateForm(
      [
        {
          fieldId: "idnumber",
          required: true,
          validation: "idnumber",
        },
        {
          fieldId: "firstName",
          required: true,
        },
        {
          fieldId: "lastName",
          required: true,
        },
        {
          fieldId: "contact",
          validation: "cellphone",
          required: true,
        },
        {
          fieldId: "address",
          required: true,
        },
      ],
      input
    );

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    await checkVoterRegistration({
      variables: {
        input,
      },
    });

    setLoading(false);
  };

  return (
    <Form layout="vertical">
      <Title level={5}>{title}</Title>
      <Typography>{description}</Typography>
      <Divider />
      <Form.Item
        label={config[process.env.REACT_APP_SITE_ID].voterId}
        hasFeedback={!!errors["idnumber"]}
        validateStatus={!!errors["idnumber"] ? "error" : ""}
        help={errors.idnumber}
      >
        <Input
          placeholder={`Enter ${config[process.env.REACT_APP_SITE_ID].voterId}`}
          value={values.idnumber}
          onChange={(e) =>
            onChange({ idnumber: e.target.value.replace(/\s/g, "") })
          }
        />
      </Form.Item>
      <Form.Item
        label="First Name"
        hasFeedback={!!errors["firstName"]}
        validateStatus={!!errors["firstName"] ? "error" : ""}
        help={errors.firstName}
      >
        <Input
          placeholder="Enter first name"
          value={values.firstName}
          onChange={(e) => onChange({ firstName: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="Last Name"
        hasFeedback={!!errors["lastName"]}
        validateStatus={!!errors["lastName"] ? "error" : ""}
        help={errors.lastName}
      >
        <Input
          placeholder="Enter last name"
          value={values.lastName}
          onChange={(e) => onChange({ lastName: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="Cellphone Number"
        hasFeedback={!!errors["contact"]}
        validateStatus={!!errors["contact"] ? "error" : ""}
        help={errors.contact}
      >
        <Input
          placeholder="Enter cellphone number"
          value={values.contact}
          onChange={(e) =>
            onChange({ contact: e.target.value.replace(/\s/g, "") })
          }
        />
      </Form.Item>
      <Form.Item
        label="Home Address"
        hasFeedback={!!errors["address"]}
        validateStatus={!!errors["address"] ? "error" : ""}
        help={errors.address}
      >
        <AutoSearchComponent onSetLocation={(e) => onChange({ address: e })} />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          size="large"
          htmlType="submit"
          className="login-form-button"
          onClick={onSearch}
          disabled={loading}
        >
          {loading ? "Loading" : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LookupScreen;
