import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import Marker from "./Marker";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const MapContainer = ({
  google,
  latitude,
  longitude,
  markers,
  defaultZoom = 9,
}) => {
  const mapRef = useRef();
  const [zoom, setZoom] = useState(defaultZoom);
  const [bounds, setBounds] = useState(null);

  const points = markers.map((marker, index) => ({
    type: "Feature",
    properties: {
      id: index,
      cluster: false,
    },
    geometry: {
      type: "Point",
      coordinates: [marker.lng, marker.lat],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <div style={mapStyles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDD-sH7us86DAIOVBnDf4gBlunje6dcXr4" }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster && pointCount > 1) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${30 + (pointCount / points.length) * 20}px`,
                    height: `${30 + (pointCount / points.length) * 20}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`${cluster.properties.id}`}
              lat={latitude}
              lng={longitude}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default MapContainer;
