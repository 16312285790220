import React from "react";
import ChartistGraph from "react-chartist";

import { Statistic } from "antd";
//import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { Flex } from "rebass";
// import { navigate } from "@reach/router";
import queryString from "query-string";

import { getLowestLevel } from "../../common/utils";
import { AutoScaleAxis } from "chartist";
import { Bar, Line } from "@ant-design/charts";

export const LineGraph = (props) => {
  const config = {
    data: props.data.map((p) => {
      const d = new Date(p.tag);
      console.log(d, p.tag);
      var date = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();

      return {
        ...p,
        tag: `${date}/${month}/${year}`,
      };
    }),
    padding: "auto",
    xField: "tag",
    yField: "count",
    xAxis: {
      tickCount: 5,
      type: "time",
    },
    smooth: true,
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  return <Line {...config} />;
};

export const BarGraph = (props) => {
  const config = {
    data: props.data.map((p) => {
      const tagData = props.tags.find((tag) => tag.name === p.tag);

      return {
        value: tagData.name,
        year: p.count,

        meta: tagData || {},
      };
    }),
    color: ({ type }) => {
      return type === "美容洗护" ? "#FAAD14" : "#5B8FF9";
    },
    xField: "value",
    yField: "year",
    seriesField: "year",
    legend: {},
  };
  return <Bar {...config} />;

  /*
  var data = {
    labels: props.data.map((s) => s.tag),
    series: [
      props.data.map((s) => {
        const tagData = props.tags.find((tag) => tag.name === s.tag);

        return {
          meta: {
            ...(tagData || {}),
            structure: s.structure,
            count: s.count,
          },
          data: s.count,
        };
      }),
    ],
  };

  var options = {
    horizontalBars: true,
    seriesBarDistance: 10,
    onlyInteger: true,
    height: "500px",
    axisX: {
      offset: 60,
    },
    axisY: {
      offset: 80,

      labelInterpolationFnc: function (value) {
        return value;
      },
      scaleMinSpace: 15,
    },
    chartPadding: {
      right: 50,
      left: 50,
    },
    plugins: [],
  };

  if (0) {
    options.plugins.push(
     
    );
  }

  return (
    <>
      <ChartistGraph
        listener={{
          draw: function (t) {
            if (t.type === "bar") {
              t.element._node.onclick = () => {
                let params = props.query;
                params.tags = [t.meta.id];

                params.total = t.meta.count;

                const stringified = queryString.stringify(params, {
                  arrayFormat: "index",
                });

                const structure = getLowestLevel(params);
                const [type] = Object.keys(structure);
                const value = structure[type];

                window.open(`/voters/${type}/${value}?${stringified}`);
              };

              if (t.meta && t.meta.color) {
                t.element.attr({
                  style: "stroke: " + t.meta.color + ";",
                });
              }

              t.element._node.onpointerleave = (e) => {};

              t.element._node.onpointerover = (e) => {
                t.element.attr({
                  style: "cursor: pointer; stroke: " + t.meta.color + ";",
                });
              };
            }
          },
        }}
        data={data}
        options={options}
        type={"Bar"}
      />
    </>
  );
  */
};

export const PieGraph = (props) => {
  const data = {
    series: props.data.map((r, index) => ({
      value: r.count,
      name: `${r.tag} (${r.count})`,
      className: `ct-series ct-pie-${index}`,
    })),
  };

  const options = {
    showLabel: false,
    plugins: [],
  };

  const responsiveOptions = [
    [
      "screen and (min-width: 768px)",
      {
        height: "500px",
      },
    ],
    [
      "screen and (max-width: 768px)",
      {
        height: "300px",
      },
    ],
  ];

  return (
    <div>
      <ChartistGraph
        data={data}
        options={options}
        responsiveOptions={responsiveOptions}
        type={"Pie"}
      />
    </div>
  );
};

export const SimpleLineGraph = ({ data, high, showPercentage, children }) => {
  var options = {
    height: "500px",
    axisY: {
      onlyInteger: true,
      maximum: 100,
      labelInterpolationFnc: function (value, index) {
        return `${value}${showPercentage ? "%" : ""}`;
      },
    },

    high: high ? high : null,
  };

  var type = "Line";

  return (
    <div>
      <ChartistGraph data={data} type={type} options={options} />
      {children}
    </div>
  );
};
