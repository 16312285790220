import React from "react";
import { Layout, Spin } from "antd";
import SideDrawer from "../../components/SideDrawer";
import config from "../../common/config";
import { Box } from "rebass";
const { Header, Content, Footer } = Layout;

const App = (props) => {
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Box className="demo-logo" mr={[2]}>
          <img
            alt="logo"
            style={{
              width: "80px",
              height: "auto",
            }}
            src={config[process.env.REACT_APP_SITE_ID].logo}
          />
        </Box>
        <SideDrawer visible={true} setVisible={() => {}} />
      </Header>
      <Content style={{ minHeight: "80vh", padding: "0 16px" }}>
        {props.isLoading ? <Spin></Spin> : props.children}
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        {config[process.env.REACT_APP_SITE_ID].copyright} ALL RIGHTS RESERVED.
        &copy; {new Date().getFullYear()}. {process.env.REACT_APP_VERSION}
      </Footer>
    </Layout>
  );
};
export default App;
