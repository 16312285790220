import React, { useContext, useState, useEffect } from "react";
import { navigate } from "@reach/router";

import {
  Spin,
  Button,
  Modal,
  Card,
  Alert,
  List,
  Divider,
  Switch,
  Typography,
} from "antd";
import { useMutation, useApolloClient, useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { Flex, Box } from "rebass";

import { ResultsContext } from "../Search/ResultsProvider";
import { GET_VOTER } from "../Voter/VoterProfileScreen";
import {
  validateForm,
  getFormattedNumber,
  formatAddress,
} from "../../common/utils";
import FormContainer from "../Form/FormContainer";
import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Title from "antd/es/typography/Title";
import AutoSearchComponent from "../../components/Autocomplete";

const { confirm } = Modal;

export const formatValues = (values, tags) => {
  let obj = {
    tags: [],
    comments: [],
  };
  Object.keys(values).forEach((val) => {
    if (val.startsWith("tag")) {
      obj.tags = [...obj.tags, ...values[val]];
    } else if (val.startsWith("select")) {
      obj.tags = [...obj.tags, values[val]];
    } else if (["pd"].includes(val)) {
      obj[val] = parseInt(values[val]);
    } else if (["comments"].includes(val)) {
      obj.comments = Object.keys(values[val]).reduce((result, item) => {
        const tag = tags.find((tag) => tag.id === item);

        result.push(`${tag.code} - ${tag.name}: ${values[val][item]}`);

        return result;
      }, []);
    } else if (val === "cellphone") {
      obj.contact = getFormattedNumber(
        `${values[val].replace(/[^0-9]/g, "")}`,
        config[process.env.REACT_APP_SITE_ID].country.iso
      );
    } else if (val === "address") {
      obj.address = formatAddress(values[val]);
    } else {
      obj[val] = values[val];
    }
  });

  return obj;
};

const CanvassScreen = (props) => {
  const { setVoter, voter } = useContext(ResultsContext);
  const [step, setStep] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [errors, setError] = useState({});
  const [updateVoter] = useMutation(UPDATE_VOTER);
  useEffect(() => {
    if (!voter) {
      client
        .query({
          query: GET_VOTER,
          variables: { registrationNumber: props.registrationNumber },
        })
        .then(({ data }) => {
          setVoter(data.voter);
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [values, setValues] = useState({
    contact: "",
    comments: {},
  });
  const [changed, setChanged] = useState(false);

  const client = useApolloClient();
  const { loading, error, data } = useQuery(GET_CANVASS_OPTIONS);

  if (error) return <p>{error.message}</p>;
  if (loading || !voter) return <Spin />;

  const { tags, canvassForm: steps = [] } = data;

  const currentStep = steps[step];

  const formEvents = (step, form) => {
    setError({});

    const validationErrors = validateForm(form, values);

    if (Object.keys(validationErrors).length) {
      setError(validationErrors);
      return;
    }

    switch (step) {
      case steps.length - 1:
        updateVoter({
          variables: {
            voterId: voter.registrationNumber,
            input: formatValues(values, tags),
          },
        }).then(() => {
          navigate(`/voter/${voter.registrationNumber}/success`);
        });

        break;
      default:
      case 2:
      case 0: {
        setStep(step + 1);
        break;
      }
    }
  };

  const onNext = () => {
    const { fields } = currentStep;
    formEvents(step, fields);
  };

  const onBack = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
    if (!changed) {
      setChanged(true);
    }
  };

  const onExit = () => {
    if (changed) {
      confirm({
        title: "Are you sure you want to exit the canvass?",
        content: "All information entered will be discarded.",
        onOk() {
          navigate("/search");
        },
        onCancel() {},
      });
    } else {
      navigate("/search");
    }
  };

  const onCommentSet = (id, value) => {
    setValues({
      ...values,
      comments: {
        ...values.comments,
        [id]: value,
      },
    });
  };

  return (
    <AdminScreen title={"Canvass"} onBack={onExit}>
      <Card
        title={`${voter.firstName} ${voter.lastName}`}
        extra={`${voter.registrationNumber} | ${
          voter.pollingDivision.constituency?.name ?? "-"
        }`}
      >
        <List
          itemLayout="horizontal"
          dataSource={[
            {
              title: "Polling Division",
              value:
                voter.pollingDivision.pdName || voter.pollingDivision.pdCode,
            },
            {
              title: "Province",
              value: voter.pollingDivision.province.name,
            },
            {
              title: "Municipality",
              value: voter.pollingDivision.constituency?.name ?? "-",
            },
            voter.pollingDivision.electoralDivision && {
              title: "Ward",
              value: voter.pollingDivision.electoralDivision.name,
            },
          ].filter((p) => !!p.value)}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.value} />
            </List.Item>
          )}
        />
        <Divider />
        <Box mb={[2]}>
          <Title level={5}>Voter Address</Title>
          <div style={{ marginBottom: 16 }}>
            <Typography.Text strong style={{ marginRight: "10px" }}>
              Find on Map:
            </Typography.Text>
            <Switch checked={showMap} onChange={() => setShowMap(!showMap)} />
          </div>
          <AutoSearchComponent
            showMap={showMap}
            onSetLocation={(e) => onSetValues({ address: e })}
          />
        </Box>
        <FormContainer
          errors={errors}
          form={{
            fields: currentStep.fields.map((field) => {
              if (field.type === "inputRadio") {
                const [, fieldParam] = field.fieldId.split(".");
                return {
                  ...field,
                  tags: tags.filter((tag) => tag.code === fieldParam),
                };
              }

              if (field.type === "select") {
                return {
                  ...field,
                  error: errors[field.fieldId],
                  options:
                    field.options &&
                    field.options.map((option) => {
                      const taggedOption = tags.find(
                        (tag) => tag.name === option.value
                      );

                      if (taggedOption) {
                        return {
                          ...option,

                          id: taggedOption.id,
                        };
                      }
                      return option;
                    }),
                };
              }

              return field;
            }),
          }}
          values={values}
          onSetValues={onSetValues}
          onCommentSet={onCommentSet}
        />
        <Box pt={[4]}>
          {Object.keys(errors).length
            ? Object.keys(errors).map((e) => (
                <Alert message={errors[e]} type="error" />
              ))
            : null}
        </Box>
        <Flex py={[4]}>
          <Button
            style={{ marginRight: "10px" }}
            block
            type={"primary"}
            size="large"
            onClick={onNext}
          >
            {steps.length - 1 === step ? "Submit Canvass" : "Next"}
          </Button>
          <Button type="link" size="normal" onClick={onBack}>
            Previous
          </Button>
        </Flex>
      </Card>
    </AdminScreen>
  );
};

export default CanvassScreen;

const GET_CANVASS_OPTIONS = gql`
  query getCanvassOptions {
    tags {
      name
      category
      code
      id
      hasComment
      commentDescription
      description
    }

    canvassForm {
      title
      fields {
        type
        fieldId
        label
        placeholder
        required
        validation

        multiple
        sort
        limit
        options {
          label
          value
        }
      }
    }
  }
`;

export const UPDATE_VOTER = gql`
  mutation updateVoter($voterId: String!, $input: UpdateVoterInput!) {
    updateVoter(voterId: $voterId, input: $input) {
      id
      firstName
      middleName
      lastName
      registrationNumber
      voterId
      alias
      contact {
        id
        value
        type
      }
      tags {
        code
      }
      gender
      address {
        addressLine1
        addressLine2
        postalCode
      }
      updatedAt
      profession
      pollingDivision {
        id
        constituency: municipality {
          name
        }
        electoralDivision: ward {
          name
        }
        pdCode: code
      }
      tags {
        category
        name
      }
    }
  }
`;
