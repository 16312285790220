import React from "react";
import ReactDOM from "react-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "@emotion/react";
import theme from "@rebass/preset";
import { ConfigProvider } from "antd";
import App from "./App";

const token = process.env.REACT_APP_PRIMSA;
// Pass your prisma endpoint to uri
const client = new ApolloClient({
  uri: process.env.REACT_APP_API,
  request: (operation) => {
    const auth = JSON.parse(localStorage.getItem("UserPNPAuth") || null);
    operation.setContext({
      headers: {
        "x-access-token": auth && auth.token ? `Bearer ${auth.token}` : "",
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider theme={{ token: { colorPrimary: "#000" } }}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
