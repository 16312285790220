import { useMutation } from "react-apollo";
import { navigate } from "@reach/router";
import { gql } from "apollo-boost";

export const CHECK_VOTER_REGISTRATION_MUTATION = gql`
  mutation CheckVoterRegistration($input: CheckVoterRegistrationInput!) {
    checkVoterRegistration(input: $input) {
      error
      updated
      success
      voter
    }
  }
`;

const useCheckVoterRegistration = () => {
  const [checkVoterRegistrationMutation, { loading, error, data }] =
    useMutation(CHECK_VOTER_REGISTRATION_MUTATION);

  const checkVoterRegistration = async (input) => {
    try {
      const response = await checkVoterRegistrationMutation(input);
      if (response.data.checkVoterRegistration.success) {
        const idnumber = response.data.checkVoterRegistration.voter.Id;
        navigate(`/voter/${idnumber}`);
      }
    } catch (e) {
      // Handle error
      console.error("Error checking voter registration:", e);
    }
  };

  return {
    checkVoterRegistration,
    loading,
    error,
    data,
  };
};

export default useCheckVoterRegistration;
