import React, { useState, useEffect } from "react";
import saveFile from "save-as-file";
import { Spin, Button, Alert, Select, Form } from "antd";
import { Flex, Box } from "rebass";
import { getStoredUserAuth, getLowestLevel } from "../../common/utils";
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from "react-awesome-query-builder";

import "@react-awesome-query-builder/antd/css/styles.css";
import siteConfig from "../../common/config";
import config from "../../common/config";

const groupTags = (tags) => {
  return tags.reduce((acc, tag) => {
    const entry = { value: tag.id, title: tag.name };
    if (!acc[tag.code]) {
      acc[tag.code] = {
        category: tag.category,
        tags: [entry],
      };
    } else {
      acc[tag.code].tags.push(entry);
    }
    return acc;
  }, {});
};

const getConfig = (groupTags) => {
  const selectOptions = {
    type: "select",
    valueSources: ["value"],
  };

  return Object.keys(groupTags).reduce((acc, key) => {
    const { category, tags } = groupTags[key];

    /* Convert the category to snake case key - not necessary, just fun */
    const configKey = category
      .toLowerCase()
      .replace(/\(.*\)/g, "")
      .trim()
      .replace(/\s/g, "_");

    acc[configKey] = {
      label: category,
      listValues: tags,
      ...selectOptions,
    };
    return acc;
  }, {});
};

const DownloadsContainer = ({ structure, tags }) => {
  const [downloading, setDownloading] = useState(false);
  const [errors, setError] = useState({});
  const [queryState, setQueryState] = useState({ tree: null, config: null });
  const [form, setForm] = useState({ group: "1", break: "1" });

  /* query builder related variables and functions */
  useEffect(() => {
    const tagGroups = groupTags(tags);
    const config = {
      ...BasicConfig,
      operators: {
        equal: BasicConfig.operators.equal,
        not_equal: BasicConfig.operators.not_equal,
        select_equals: BasicConfig.operators.select_equals,
        select_not_equals: BasicConfig.operators.select_not_equals,
      },
      settings: {
        ...BasicConfig.settings,
        maxNesting: 2,
      },
      fields: {
        "tag.A": {
          type: "!struct",
          label: "Tags",
          subfields: {
            ...getConfig(tagGroups),
          },
        },
        profile: {
          type: "!struct",
          label: "Voter Profile",
          subfields: {
            streetName: {
              type: "text",
              label: "Street Name",
              valueSources: ["value"],
            },
            firstName: {
              type: "text",
              label: "First Name",
              valueSources: ["value"],
            },
            lastName: {
              type: "text",
              label: "Last Name",
              valueSources: ["value"],
            },
            gender: {
              type: "text",
              label: "Gender",
              valueSources: ["value"],
            },
            occupation: {
              type: "text",
              label: "Occupation",
              valueSources: ["value"],
            },
          },
        },
      },
    };
    const queryValue = {
      id: QbUtils.uuid(),
      type: "group",
    };

    setQueryState({
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config,
    });

    return () => {};
  }, [tags]);

  const onChangeQuery = (immutableTree, config) => {
    setQueryState({ tree: immutableTree, config });
  };

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite" style={{ margin: 0 }}>
        <Builder {...props} />
      </div>
    </div>
  );

  if (!queryState.config) return <Spin />;

  const generateReport = (type) => {
    setDownloading(true);
    setError({});
    const query = QbUtils.jsonLogicFormat(queryState.tree, queryState.config);

    var raw = JSON.stringify({
      form,
      siteId: process.env.REACT_APP_SITE_ID,
      template: config[process.env.REACT_APP_SITE_ID].template,
      structure: getLowestLevel(structure),
      query,
      type: type || siteConfig[process.env.REACT_APP_SITE_ID].reportTemplate,
    });

    fetch(`${process.env.REACT_APP_API}/downloads`, {
      body: raw,
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        authorization: `Bearer ${getStoredUserAuth().token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("No voters match criteria or Error downloading report");
        }

        return res;
      })
      .then((response) => response.blob())
      .then((blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `canvass.csv`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        setDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setDownloading(false);
        setError({
          download: "Error creating pdf. Please try again or refine the query.",
        });
      });
  };

  const generatePdf = () => {
    setDownloading(true);
    setError({});
    const query = QbUtils.jsonLogicFormat(queryState.tree, queryState.config);

    if (!query.logic && !structure.pd) {
      setDownloading(false);
      setError({
        download: "Voter lists require a polling division to be selected.",
      });

      return;
    }

    var raw = JSON.stringify({
      form,
      siteId: process.env.REACT_APP_SITE_ID,
      template: config[process.env.REACT_APP_SITE_ID].template,
      structure: getLowestLevel(structure),
      query,
    });

    fetch(process.env.REACT_APP_PDF_URL, {
      body: raw,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        authorization: `Bearer ${getStoredUserAuth().token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("No voters match criteria or Error downloading PDF");
        }
        return res.blob();
      })
      .then((res) => {
        setDownloading(false);
        saveFile(res, "download.pdf");
      })
      .catch((err) => {
        setDownloading(false);
        setError({
          download: "Error creating pdf. Please try again or refine the query.",
        });
      });
  };

  return (
    <Flex flexWrap={["wrap", "nowrap"]}>
      <Box px={[5, 10]} mr={[0, 5]} width={[1, 1, 6 / 12]}>
        <Query
          {...queryState.config}
          value={queryState.tree}
          onChange={onChangeQuery}
          renderBuilder={renderBuilder}
        />
      </Box>
      <Box px={[5, 10]} width={[1, 1, 6 / 12]}>
        <Box mb={[5, 10]}>
          <Alert
            message={
              "Use the form to select voter tags and download canvass sheet."
            }
            type="success"
          />
        </Box>
        {siteConfig[process.env.REACT_APP_SITE_ID].gotvSheet ? (
          <Box my={[2]}>
            <Button
              onClick={() => generateReport("gotv")}
              disabled={downloading}
              type={"primary"}
              size="small"
            >
              {downloading ? <Spin /> : "Download GOTV XLS"}
            </Button>
          </Box>
        ) : null}

        {siteConfig[process.env.REACT_APP_SITE_ID].excel ? (
          <Button
            onClick={() => generateReport()}
            disabled={downloading}
            type={"primary"}
            size="small"
          >
            {downloading ? <Spin /> : "Download Canvass XLS"}
          </Button>
        ) : null}

        {errors.download ? (
          <Box mb={[5, 10]}>
            <Alert message={errors.download} type="error" />
          </Box>
        ) : null}
        <Form.Item label="Sort By">
          <Select
            value={form.group}
            onChange={(value) => setForm({ ...form, group: value })}
            style={{ width: "100%" }}
          >
            <Select.Option value="1">Surname, Firstname</Select.Option>
            <Select.Option value="2">
              Street Name, Surname, Firstname
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Page Break By">
          <Select
            value={form.break}
            onChange={(value) => setForm({ ...form, break: value })}
            style={{ width: "100%" }}
          >
            <Select.Option value="1">PD</Select.Option>
            <Select.Option value="2">Streetname, PD</Select.Option>
          </Select>
        </Form.Item>
        <Button
          onClick={generatePdf}
          disabled={downloading}
          type={"primary"}
          size="large"
        >
          {downloading ? <Spin /> : "Download PDF"}
        </Button>
      </Box>
    </Flex>
  );
};

export default DownloadsContainer;
