import React from "react";
import { Box, Heading } from "rebass";
import { Button } from "antd";
import { Link } from "@reach/router";
import GotvContainer from "../../Gotv/GotvContainer";

const CallingApp = ({ electionCode }) => {
  return (
    <GotvContainer components={{ constituency: { code: "code" }, pd: { code: "id" } }}>
      {() => (
        <Box mt={[3]} mx="auto" px={[3]}>
          <Heading mb={[3]} size="h4">
            Calling
          </Heading>
          <Link to={`/gotv/calling/${electionCode}/dial`}>
            <Button type="primary" block size="large">
              Start Calling
            </Button>
          </Link>
        </Box>
      )}
    </GotvContainer>
  );
};

export default CallingApp;
