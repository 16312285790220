import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Empty, Row, Spin } from "antd";
import { Box } from "rebass";
import { groupBy } from "lodash";
import TableTags from "./ui/Table";
import CardComponent from "./ui/CardComponent";

const Issues = (props) => {
  const { loading, error, data } = useQuery(ISSUE_COUNT, {
    variables: {
      input: props.query,
    },
  });

  if (error) return `Error! ${error.message}`;

  if (loading || !data)
    return (
      <Box p={[10]} sx={{ textAlign: "center" }}>
        <Spin />
      </Box>
    );

  const { getIssueCounts } = data;

  const issueTotal = getIssueCounts.reduce((result, item) => {
    return (result += item.count);
  }, 0);

  if (issueTotal < 1) {
    return (
      <Box style={{ width: "100%" }}>
        <Empty />
      </Box>
    );
  }

  const issueData = groupBy(
    getIssueCounts.map((c) => {
      return {
        ...c,
        total: issueTotal,
        perCanvassed: ((c.count / issueTotal) * 100).toFixed(2),
      };
    }),
    (i) => i.structure.name
  );

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      sorter: (a, b) => a.tag.length - b.tag.length,
      sortDirections: ["descend"],
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Percentage",
      dataIndex: "perCanvassed",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.count - b.count,
    },
  ];

  return (
    <Row>
      {issueData &&
        Object.keys(issueData).map((key) => {
          const total = issueData[key].reduce(
            (result, item) => (result += item.count),
            0
          );

          return (
            <CardComponent
              title={`${props.title}: ${key}`}
              key={`${props.title}: ${key}`}
            >
              <TableTags
                meta={issueTotal}
                total={total}
                {...props}
                structure={key}
                key={new Date()}
                data={issueData[key]}
                columns={columns}
              />
            </CardComponent>
          );
        })}
    </Row>
  );
};

export default Issues;

const ISSUE_COUNT = gql`
  query getIssueCounts($input: ReportingQueryInput) {
    getIssueCounts(input: $input) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
  }
`;
